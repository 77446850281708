<template>
  <div style="border-top: solid 1px lightgrey;background-color: #C8E6C9;" v-if="isRoot">
    <div>
      <div align="center">
          <h2 style="margin-top: 10px">
            Gestion de commentaire(s)
          </h2>
        <v-col>
          <v-card class="pa-2">
            <v-col class="px-0">
              <v-row>
                <v-col>
                  Début<Datepicker v-model="dateStart" locale="fr" format="dd/MM/yyyy HH:mm" :clearable="false"
                    :cancelText="'Fermer'" :selectText="'Valider'"></Datepicker>
                </v-col>
                <v-col>
                  Fin<Datepicker v-model="dateEnd" locale="fr" format="dd/MM/yyyy HH:mm" :clearable="false"
                    :cancelText="'Fermer'" :selectText="'Valider'"></Datepicker>
                </v-col>
                <v-col>
                  Technicien
                  <v-select variant="outlined" hide-details density="compact" v-model="selectedTech"
                    :items="technicienList" style="
                      ">
                  </v-select>
                </v-col>
              </v-row>
            </v-col>
            <div>
              <div style="" class="mt-3">
                <v-textarea label="Commentaire" variant="outlined" hide-details density="compact" v-model="selectedCom"
                  style="
                      ">
                </v-textarea>
              </div>
            </div>
            <div style="padding-top: 10px">
              <v-btn class="button-state" style="
                    background-color: #2e7d32;
                    font-size: 15px;
                    padding: 5px;
                    margin: 2px;
                    color: white;
                    cursor: pointer;
                  " @click="creatNotDispo()">
                Ajouter
              </v-btn>
              <v-btn class="button-state" style="
                    background-color: #d84315;
                    font-size: 15px;
                    padding: 5px;
                    margin: 2px;
                    color: white;
                    cursor: pointer;
                  " @click="cancelCreationDispo()">
                Annuler
              </v-btn>
            </div>
          </v-card>
        </v-col>
      </div>
    </div>
    <v-col>
      <v-card class="pa-2">
        <table style="width: 100%">
          <thead style="background-color: #f5f5f5">
            <tr align="center">
              <th>Début</th>
              <th>Fin</th>
              <th>Technicien</th>
              <th>Commentaire</th>
              <th v-if="isRoot"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="tech in getTechnicienListAvailable" :key="tech['Clé primaire']">
              <td>{{ tech.DEBUT }}</td>
              <td>{{ tech.FIN }}</td>
              <td>{{ tech.TECHNICIEN }}</td>
              <td>{{ tech.title }}</td>
              <td v-if="isRoot" align="center">
                <v-btn variant="tonal" style="color: red; font-size: 20px; cursor: pointer" @click="removeNotDispo(tech)">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </td>
            </tr>
          </tbody>
        </table>
      </v-card>
    </v-col>
    <div align="center">
      <v-btn class="mb-3 mt-1" style="
            background-color: red;
            color: white;
            border-style: none;
            padding: 5px;
            border-radius: 5px;
            font-size: 16px;
            cursor: pointer;
          " @click="closeModal">
        Fermer
      </v-btn>
    </div>

  </div>
  <!-- </div>
  </div> -->
</template>

<script>
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import engine from "../core/index";
import moment from "moment";

export default {
  data() {
    return {
      selectedCom: "",
      isEditionOpen: false,
      dateStart: "",
      dateEnd: "",
      selectedTech: "",
      selectedType: "",
      filters: "",
    };
  },
  components: {
    Datepicker,
  },
  props: {
    isRoot: {},
    technicienList: {},
    commentaireListe: {},
    selectedTechniciens: {},
    addOrRmvTechnicien: {},
    setSelectedTechniciens: {},
    setCommentaires: {},
    updateTechToShow: {},
  },
  mounted() {
    // let that = this;
    engine.get_commentaires_liste(function (data) {
      console.log("get_commentaires_liste", data);
      // if (data.code == 0) {
      //   that.typeList = data.data.data.map((val) => val.fieldData.Name);
      // }
    });
  },
  watch: {
    isEditionOpen(val) {
      if (val == true) {
        this.dateStart = moment()
          .set({ hour: 8, minute: 0 })
          .format("MM/DD/YYYY HH:mm");
        this.dateEnd = moment()
          .set({ hour: 18, minute: 0 })
          .format("MM/DD/YYYY HH:mm");
        // this.selectedType = this.typeList[0];
        this.selectedTech = this.technicienList[0].title;
      }
    },
  },
  computed: {
    getTechnicienListAvailable() {
      let list = [];
      for (let i = 0; i < this.commentaireListe.length; i++) {
        let value = this.commentaireListe[i];
        value.DEBUT = moment(value.start).format("DD/MM/YYYY HH:mm");
        value.FIN = moment(value.end).format("DD/MM/YYYY HH:mm");
        list.push(value);
      }
      return list;
    },
    technicienListToShow() {
      let techList = this.technicienList;
      techList = techList.sort(function (a, b) {
        if (a.id < b.id) return -1;
        if (a.id > b.id) return 1;
        return 0;
      });

      if (this.filters == "") {
        return techList;
      } else {
        let filteredTech = [];
        for (let i = 0; i < techList.length; i++) {
          if (techList[i].id.toLowerCase().includes(this.filters.toLowerCase()))
            filteredTech.push(techList[i]);
        }
        return filteredTech;
      }
    },
  },
  methods: {
    creatNotDispo() {
      let data = {
        DEBUT: moment(this.dateStart).format("MM/DD/YYYY HH:mm"),
        FIN: moment(this.dateEnd).format("MM/DD/YYYY HH:mm"),
        TECHNICIEN: this.selectedTech,
        COMMENTAIRE: this.selectedCom,
        state: 1,
      };
      let that = this;
      engine.set_commentaire_by_key(
        "",
        data,
        function () {
          that.setCommentaires();
        }
      );
      this.cancelCreationDispo();
    },
    removeNotDispo(data) {
      let that = this;
      engine.set_commentaire_by_key(
        data["Clé primaire"],
        { state: 0 },
        function () {
          that.setCommentaires();
        }
      );
    },
    cancelCreationDispo() {
      this.isEditionOpen = false;
      this.dateStart = undefined;
      this.dateEnd = undefined;
      this.selectedTech = undefined;
      this.selectedType = undefined;
    },
    closeModal() {
      this.$emit("close");
    },
    saveModal() {
      this.$emit("save", {
        e: this.data.e,
        Techniciens_Nom: this.data.calendarTech,
      });
    },
  },
};
</script>

<style>
.planning-technicien .colorHover:hover {
  background-color: lightgrey;
}
</style>