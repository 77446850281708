import interactionPlugin from "@fullcalendar/interaction";

import fr from "@fullcalendar/core/locales/fr";
import { ref } from "@vue/reactivity";
import { computed } from "@vue/runtime-core";

import moment from 'moment';
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import { onMounted } from "vue";
import engine from "../core/dataApi";
import { useStore } from 'vuex'

export default function calendarData(
    handleEventClick,
    handleDateClick
) {
    const store = useStore()
    const eventList = ref([]);
    const eventData = ref([]);
    const isLoading = ref(true);
    const selectedAgency = ref([])
    const rdvTypeListe = ref([])
    const daysToHide = ref([]);
    const calendarDate = ref(undefined);
    const currentCalendarDate = ref({ start: undefined, end: undefined })
    const selectedView = ref('resourceTimeline1Day')
    const statutIcons = ref({
        0: '',
        1: '✅',
        2: '⚠️',
        3: '❌'
    });

    const eventColorByType = ref({
        // FREE: { label: 'Multie', color: '#757575' },
    })
    const settingsData = ref({});
    const isModalsOpen = ref({
        'settingsModal': { value: false, data: {} },
        'calendarModal': { value: false, data: {} },
    });
    const agencyList = ref([]);
    const agencyShortList = ref([]);
    const agencyLongList = ref([]);

    const viewList = ref({
        resourceTimeline1Day: {
            type: 'resourceTimeGrid',
            duration: { days: 1 },
            buttonText: '1 J',
            slotDuration: "00:20",
        },
        resourceTimeline3Day: {
            type: 'resourceTimeGrid',
            duration: { days: 3 },
            buttonText: '3 J',
            slotDuration: "00:15"
        },
        resourceTimeline7Day: {
            type: 'resourceTimeGrid',
            duration: { days: 7 },
            buttonText: '7 J',
            slotDuration: "00:15"
        },
    })

    const calendarOptions = computed(function() {
        let option = {}

        option = {
            timeZone: 'local',
            selectable: true,
            schedulerLicenseKey: '0359429366-fcs-1650894843',
            nowIndicator: true,
            allDaySlot: false,
            resourceAreaWidth: '255px',
            datesAboveResources: true,
            plugins: [resourceTimeGridPlugin, interactionPlugin],
            initialView: selectedView.value,
            // height: "91vh",
            titleFormat: { year: 'numeric', month: 'short', day: 'numeric', weekday: 'long' },
            locale: fr,
            slotEventOverlap: true,
            hiddenDays: daysToHide.value,
            datesSet: handleDateChange,
            eventClick: handleEventClick,
            dateClick: isAdmin.value ? handleDateClick : '',
            events: eventList.value,
            slotMinTime: '08:00:00',
            slotMaxTime: '19:00:00',
            resources: selectedAgency.value,
            resourceOrder: 'INDEX',
            views: viewList.value,
            customButtons: {
                parameter: {
                    text: 'Paramètre',
                    click: openParametrage
                },
                daySelector: {
                    text: 'Calendrier',
                    click: openCalendar
                }
            },
            headerToolbar: {
                left: 'prev today',
                center: 'title',
                right: 'next'
            },

        };
        return option;
    });


    const setNewView = function(view) {
        let newSelectedAgency
        if (view == 'resourceTimeline1Day') {
            agencyList.value = agencyLongList.value
        } else {
            agencyList.value = agencyShortList.value
        }
        newSelectedAgency = agencyList.value.filter((val) => selectedAgency.value.map((val2) => val2.id).includes(val.id))
        selectedAgency.value = newSelectedAgency
        let calendarApi = this.$refs.fullCalendar.getApi()
        let data = calendarApi.view.getCurrentData().viewApi
        let dates = { startStr: moment(data.activeStart).utc().format(), endStr: moment(data.activeEnd).utc().format() }
        dates.forceUpdate = true
        handleDateChange(dates, true)
    }


    const setModal = function(key, value, data) {
        isModalsOpen.value[key] = { value, data }
    }

    const isAdmin = computed(function() {
        return store.state.user.admin == 1 ? true : false
    })

    const openParametrage = function() {
        setModal('settingsModal', true, settingsData.value)
    }

    const openCalendar = function() {
        setModal('calendarModal', true, settingsData.value)
    }

    const handleDateChange = function(date) {
        eventData.value = []
        let startTimestanp = moment(date.startStr).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).format('x')
        let endTimestanp = moment(date.endStr).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).format('x')
        calendarDate.value = parseInt(moment(date.startStr).format('x'))
        if (date.forceUpdate || (currentCalendarDate.value.start != startTimestanp && currentCalendarDate.value.end != endTimestanp)) {
            updateCalendarDaysToShow()
            updateCalendarColors()
            engine.get_dispo_between_timestamp(startTimestanp, endTimestanp, function(data) {
                eventList.value = []
                if (data.code == 0) {
                    let events = data.data.data
                    eventData.value = events.map((val) => val.fieldData)
                    setEvents()
                }
                currentCalendarDate.value.start = startTimestanp
                currentCalendarDate.value.end = endTimestanp
            })
        }
    }

    const updateCalendarColors = function() {
        engine.get_calendar_colors(function(data) {
            if (data.code == 0 && data.data.data[0].fieldData.Value != "" && data.data.data[0].fieldData.Value != undefined) {
                eventColorByType.value = {...eventColorByType.value, ...JSON.parse(data.data.data[0].fieldData.Value) }
            }
        })
    }

    const updateCalendarDaysToShow = function() {
        engine.get_day_to_hide(function(data) {
            if (data.code == 0) {
                daysToHide.value = data.data.data[0].fieldData.Value.split(',').map((val) => parseInt(val))
            }
        })
    }

    const updateDispo = function() {
        updateCalendarDaysToShow()
        updateCalendarColors()
        engine.get_dispo_between_timestamp(currentCalendarDate.value.start, currentCalendarDate.value.end, function(data) {
            eventData.value = []
            eventList.value = []
            if (data.code == 0) {
                let events = data.data.data
                eventData.value = events.map((val) => val.fieldData)
                setEvents()
            }
        })
    }

    onMounted(() => {
        calendarDate.value = parseInt(moment().format('x'))
        engine.get_day_to_hide(function(data) {
            if (data.code == 0) {
                daysToHide.value = data.data.data[0].fieldData.Value.split(',').map((val) => parseInt(val))
            }
        })
        engine.get_agency_liste(function(data) {
            if (data.code == 0) {
                let pointRelais = data.data.data.data.map((val) => val.fieldData)
                for (let i = 0; i < pointRelais.length; i++) {
                    let item = JSON.parse(JSON.stringify(pointRelais[i]))
                    let itemShort = JSON.parse(JSON.stringify(pointRelais[i]))

                    itemShort['id'] = itemShort['PR_ID_AG']
                    itemShort['title'] = itemShort['WEB_LABEL'].slice(0, 2)
                    itemShort['short'] = itemShort['WEB_LABEL'].slice(0, 2)
                    itemShort['long'] = itemShort['WEB_LABEL']

                    agencyShortList.value.push(itemShort)

                    item['id'] = item['PR_ID_AG']
                    item['title'] = item['WEB_LABEL']
                    item['short'] = item['WEB_LABEL'].slice(0, 2)
                    item['long'] = item['WEB_LABEL']
                    agencyLongList.value.push(item)
                }
            }
            selectedAgency.value = agencyLongList.value
            agencyList.value = agencyLongList.value
            isLoading.value = false
        })
        engine.get_rdv_type_liste(function(data) {
            rdvTypeListe.value = data.data.data.data.map((val) => ({ label: val.fieldData.LABEL_TECH, KEY: val.fieldData.KEY }))
            for (let i = 0; i < rdvTypeListe.value.length; i++) {
                eventColorByType.value[rdvTypeListe.value[i].KEY] = {
                    label: rdvTypeListe.value[i].label,
                    color: "#5C6BC0"
                }
            }
        })

    })

    const setEvents = function() {
        let events = []
        eventList.value = []
        console.log("------------");
        console.log("eventData", eventData.value);
        console.log("agencyList", agencyList.value);
        for (let i = 0; i < eventData.value.length; i++) {
            let event = eventData.value[i]
            let agence = agencyList.value.find((val) => val.PR_NOM_AG == event.AGENCE && val.PR_VILLE_AG == event.VILLE)
            if (agence) {
                let maxTypeNbr = Object.keys(event).filter((val) => val.includes('TYPE_ID')).length
                let type = []
                for (let index = 1; index <= maxTypeNbr; index++) {
                    if (event['TYPE_ID(' + index + ')'] != '' && event['TYPE_ID(' + index + ')'] != undefined)
                        type.push(event['TYPE_ID(' + index + ')'])
                }
                let nbrType = type.filter((val) => val != '').length
                let eventDay = moment(event.JOUR).format('YYYY-MM-DD')
                event.TYPE_ID = type
                if (nbrType == 1) {
                    let title = ''
                    if (event.LIBRE == 1) {
                        if (statutIcons.value[event.RDV_VALIDE]) {
                            title += statutIcons.value[event.RDV_VALIDE] + ' '
                        }
                        title += event.NOM.toUpperCase() + ' ' + event.PRENOM + ' - ' + eventColorByType.value[type[0]].label
                    } else {
                        title = eventColorByType.value[type[0]].label
                    }
                    events.push({
                        title: title,
                        resourceIds: [agence.id],
                        resourceName: [agence.title],
                        textColor: event.LIBRE == 1 ? 'white' : 'rgb(69, 90, 100)',
                        start: eventDay + 'T' + event.DEBUT + ':00',
                        end: eventDay + 'T' + event.FIN + ':00',
                        data: event,
                        color: event.LIBRE == 1 ? eventColorByType.value[type[0]].color : agence.PR_COLOR,
                        type: type
                    })
                } else {
                    //MULTIE TYPE
                    let typeTitle = ""
                    for (let i = 0; i < type.length; i++) {
                        if (eventColorByType.value[type[i]] && eventColorByType.value[type[i]].label) {
                            typeTitle += eventColorByType.value[type[i]].label.slice(0, 3) + ' / '
                        }
                    }
                    let title = ""
                    if (event.LIBRE == 1) {
                        if (statutIcons.value[event.RDV_VALIDE]) {
                            title += statutIcons.value[event.RDV_VALIDE] + ' '
                        }
                        title += event.NOM.toUpperCase() + ' ' + event.PRENOM
                    } else {
                        title += typeTitle
                    }
                    // event.LIBRE == 1 ? statutIcons.value[event.RDV_VALIDE] + ' ' + event.NOM.toUpperCase() + ' ' + event.PRENOM + ' - ' : typeTitle,
                    events.push({
                        title: title,
                        resourceIds: [agence.id],
                        resourceName: [agence.title],
                        textColor: event.LIBRE == 1 ? 'white' : 'rgb(69, 90, 100)',
                        start: eventDay + 'T' + event.DEBUT + ':00',
                        end: eventDay + 'T' + event.FIN + ':00',
                        data: event,
                        color: event.LIBRE == 1 ? eventColorByType.value['FREE'].color : agence.PR_COLOR,
                        // color: agence.PR_COLOR,
                        type: type
                    })
                }
            }
        }
        eventList.value = events
        console.log("eventList", eventList.value);
    }

    return {
        calendarOptions,
        selectedAgency,
        agencyList,
        isAdmin,
        updateDispo,
        isLoading,
        eventColorByType,
        isModalsOpen,
        daysToHide,
        rdvTypeListe,
        viewList,
        calendarDate,
        setNewView
    };
}