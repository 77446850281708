<template>
    <v-dialog v-model="showPdf">
        <v-col>
            <object id="pdfViewer" :data="filebase64" type="application/pdf" width="100%" style="height: 80vh;width: 100%;">
            </object>
            <!-- <vue-pdf-app v-if="filebase64" style="height: 80vh;width: 500px;" :config="config" :isSidebarHidden="true"
                :isFindbarHidden="true" :pdf="filebase64"></vue-pdf-app> -->
        </v-col>
    </v-dialog>
    <v-col style="position: absolute; top:15vh" cols="12" align=center>
        <div style="max-width: 700px;">
            <v-card class="pa-4">
                <v-col align="center">
                    <h2>
                        Signature à double authentification
                    </h2>
                </v-col>
                <v-row v-if="isSigned == false">
                    <v-col>
                        <v-col align="left" style="font-size: 20px;" cols="12">
                            Signataire : {{ signatureData.CLIENT }} <br>
                            Email : {{ signatureData.EMAIL }} <br>
                            Téléphone : {{ signatureData.TEL }} <br>
                            <v-btn class="mt-3" style="background-color: #5C6BC0;color:white" @click="showPdf = true">
                                Visualiser mon document
                            </v-btn>
                        </v-col>
                        <v-col cols="12" v-if="showCodeForm == false">
                            <v-card elevation="3"
                                style="width: 100%; background-color: #66BB6A; color:white; text-transform: uppercase;"
                                @click="sendSMS" class="pa-4 btn-sign" align="center" variant="solo">
                                Recevoir un code par SMS au {{ signatureData.TEL }} pour valider la signature de mon
                                document
                            </v-card>
                        </v-col>
                        <v-col v-else>
                            <v-card elevation="3"
                                style="width: 100%; background-color: #66BB6A; color:white; text-transform: uppercase;"
                                @click="sendSMS" class="pa-1 btn-sign" align="center" variant="solo">
                                Demander un nouveau code de vérificartion
                            </v-card>
                        </v-col>
                    </v-col>
                </v-row>
                <v-col v-if="showCodeForm && !isSigned">
                    <v-card class="pa-2" align=center color="#E8F5E9">
                        <v-text-field hide-details type="number" bg-color="white" v-model="tmpCode"
                            label="Code recus par sms..."></v-text-field>
                        <v-btn @click="verifCodeIsValid" class="btn-sign mt-4 mb-2"
                            style=" background-color: #66BB6A;color:white" :loading="validationIsLoading">
                            Valider ma signature en ligne
                        </v-btn>
                    </v-card>
                </v-col>
                <v-row v-if="isSigned">
                    <v-col cols="12" class="mt-3">
                        <div style="color: #4CAF50;font-size: 25px;font-weight: bold;">
                            Votre signature est validé.
                        </div>
                    </v-col>
                    <v-col>
                        <v-btn class="mt-2" style="background-color: #5C6BC0;color:white" @click="showPdf = true">
                           Visualiser mon document signé
                        </v-btn>
                    </v-col>
                </v-row>
                <v-snackbar v-model="errorSnackBar.show" :color="errorSnackBar.color" align="center">
                    <h4>
                        {{ errorSnackBar.label }}
                    </h4>
                </v-snackbar>
            </v-card>
        </div>
    </v-col>
</template>

<script>

import engine from '@/projects/pdf-signer/core/index'
// import VuePdfApp from "vue3-pdf-app";
import moment from 'moment'

const Buffer = require('buffer').Buffer
const { PDFDocument } = require('pdf-lib');

export default {
    data() {
        return {
            isSigned: false,
            errorSnackBar: {
                show: false,
                color: undefined,
                label: undefined,
            },
            showCodeForm: false,
            tmpCode: undefined,
            validationIsLoading: false,
            showPdf: false,
            pageIsValide: true,
            id: undefined,
            filebase64: undefined,
            signatureData: undefined,
            newFileSigned: undefined,
            errorMessage: {
                time: 'Le délai de signature de ce document est dépassé.',
                signed: 'Le document sélectionné est déja signé.',
            },
            config: {
                sidebar: false,
                toolbar: false
            }
        }
    },
    components: {
        // VuePdfApp
    },
    methods: {
        closeHtmlPage() {
            close()
        },
        verifCodeIsValid() {
            let that = this
            this.validationIsLoading = true
            engine.get_double_authentification_data_from_id(this.id, function (data) {
                if (data.code == 0) {
                    let info = data.data.data[0].fieldData
                    if (info.CODE == that.tmpCode) {
                        that.errorSnackBar = {
                            color: 'green',
                            label: 'Votre signature est validé',
                            show: true,
                        }
                        that.addSignatureInFile()
                        that.isSigned = true
                        that.validationIsLoading = false
                    } else {
                        that.errorSnackBar = {
                            color: 'red',
                            label: 'Le code renseigné est incorrect',
                            show: true,
                        }
                        that.validationIsLoading = false
                    }
                } else {
                    that.errorSnackBar = {
                        color: 'red',
                        label: 'Le code renseigné est incorrect',
                        show: true,
                    }
                    that.validationIsLoading = false
                }
            })
        },
        async addSignatureInFile() {
            let pdfDoc = await PDFDocument.load(this.filebase64);
            let info = this.signatureData.FILE_PATH.split('/')
            info = info[info.length - 1].split('@@')

            let obj = {
                resaNum: info[1],
                typeValue: info[0],
                title: info[1],
                date: info[2],
                isSigned: info[3],
                position: info[4].split('-'),
                client: info[5]
            }

            let page = pdfDoc.getPages()[0];
            let posX = parseFloat(obj.position[0])
            let posY = parseFloat(obj.position[1])

            // Écrire le texte sur la page
            let font = await pdfDoc.embedFont('Helvetica');

            page.drawText(obj.client, {
                x: posX + 10,  // coordonnée X de la position de l'image
                y: posY + 45,  // coordonnée Y de la position de l'image
                size: 12,
                font,
            });

            page.drawText('Signée le ' + moment().format('DD/MM/YYYY HH:mm'), {
                x: posX + 50,  // coordonnée X de la position de l'image
                y: posY + 15,  // coordonnée Y de la position de l'image
                size: 10,
                font,
            });

            const pdfBytes = await pdfDoc.save();
            const pdfUint8Array = new Uint8Array(pdfBytes);
            const base64String = "data:application/pdf;base64," + Buffer.from(pdfUint8Array).toString('base64');
            this.newFileSigned = base64String
            this.filebase64 = base64String

            console.log("newFileSigned", this.newFileSigned);
        },
        sendSMS() {
            let that = this

            this.showCodeForm = true
            this.validationIsLoading = true
            engine.send_sms_double_authent(this.id, this.signatureData.EMAIL, this.signatureData.TEL, function (data) {
                console.log("send_sms_double_authent", data);
                that.validationIsLoading = false
            })
        }
    },
    mounted() {
        let that = this
        if (this.$route.query && this.$route.query.id) {
            this.id = this.$route.query.id

            engine.get_double_authentification_data_from_id(this.id, function (data) {
                if (data.code == 0) {
                    that.signatureData = data.data.data[0].fieldData
                    engine.get_file_from_path(that.signatureData.FILE_PATH, function (data2) {
                        that.filebase64 = "data:application/pdf;base64," + new Buffer.from(data2.data.fileBinary).toString('base64');
                    })
                } else {
                    this.pageIsValide = false
                }
            })
        } else {
            this.pageIsValide = false
        }
    }
}
</script>

<style>
.btn-sign:hover {
    border: solid 2px #1B5E20;
}

.btn-sign {
    font-weight: bold;
    border: solid 2px transparent;
}
</style>