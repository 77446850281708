<template>
  <div class="modal planning-technicien" align="center">
    <div
      class="modal-content"
      align="left"
      style="width: 700px; padding: 20px; margin-top: 15vh; font-size: 20px"
    >
      <div align="center">
        L'intervention {{ data.num }} est attribuée au technicien(s)
        <strong>{{ data.eventTech.join(" / ") }}</strong> voulez-vous
        l'attribuer à <strong>{{ selectedTechniciens.join(" / ") }}</strong> ?
      </div>
      <div v-if="planningSettings['multie-technicien']">
        <div align="center" style="margin: 10px">
          <button
            @click="setTech([...data.eventTech])"
            class="modal-btn"
            style="background-color: #3f51b5; color: white"
          >
            Selectionner le(s) technicien(s) prédéfini pour cette intervention :
            <strong
              style="
                padding: 2px 8px;
                border-radius: 10px;
                background-color: white;
                color: #3f51b5;
              "
              >{{ data.eventTech.join(" / ") }}</strong
            >
          </button>
        </div>
        <div
          style="
            border: solid 1px lightgrey;
            padding: 10px;
            border-radius: 10px;
            margin-top: 10px;
          "
        >
          <div align="">
            <h3 style="margin: 5px">Liste des techniciens sélectionné(s) :</h3>
          </div>
          <div align="center" style="width: 100%">
            <input
              style="width: 97%"
              type="text"
              v-model="filters"
              placeholder="Filtre"
            />
          </div>
          <div
            style="
              overflow: auto;
              border: solid 1px lightgrey;
              padding: 10px;
              margin: 5px;
              border-radius: 5px;
              height: 200px;
            "
          >
            <div v-for="tech in technicienListToShow" :key="tech.id">
              <div class="colorHover" @click="addTech(tech.title)">
                <input
                  type="checkbox"
                  :checked="selectedTechniciens.includes(tech.title)"
                />
                {{ tech.title }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style="margin-top: 10px; width: 100%" align="center">
        <button
          @click="saveModal"
          class="modal-btn"
          style="background-color: #4caf50; width: 40%"
        >
          Oui
        </button>
        <button
          @click="closeModal"
          class="modal-btn"
          style="background-color: #bf360c; width: 40%"
        >
          Non
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      filters: "",
      selectedTechniciens: [],
    };
  },
  props: {
    data: {},
    planningSettings: {},
    technicienList: {},
  },
  mounted() {
    this.selectedTechniciens = [...this.data.calendarTech];
  },
  computed: {
    technicienListToShow() {
      let techList = this.technicienList;
      techList = techList.sort(function (a, b) {
        if (a.id < b.id) return -1;
        if (a.id > b.id) return 1;
        return 0;
      });

      if (this.filters == "") {
        return techList;
      } else {
        let filteredTech = [];
        for (let i = 0; i < techList.length; i++) {
          if (techList[i].id.toLowerCase().includes(this.filters.toLowerCase()))
            filteredTech.push(techList[i]);
        }
        return filteredTech;
      }
    },
  },
  methods: {
    setTech(techList) {
      this.selectedTechniciens = techList;
    },
    addTech(tech) {
      if (this.selectedTechniciens.includes(tech)) {
        let index = this.selectedTechniciens.findIndex((val) => val == tech);
        this.selectedTechniciens.splice(index, 1);
      } else {
        this.selectedTechniciens.push(tech);
      }
    },
    closeModal() {
      this.$emit("close");
    },
    saveModal() {
      this.$emit("save", {
        e: this.data.e,
        Techniciens_Nom: this.selectedTechniciens,
      });
    },
  },
};
</script>

<style>
</style>