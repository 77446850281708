import { ref } from "@vue/reactivity";
// import { watch } from "vue";
import { computed } from "@vue/runtime-core";
import { onMounted } from "vue";
// import moment from "moment";
import engine from '../core/DataApi'
import { useStore } from 'vuex'

export default function contartHooks() {

    const store = useStore()
    const clientListe = ref([])
    const familyListe = ref([])
    const contrat_informations = ref([])
    const subFamilyListe = ref([])
    const parcListe = ref([])
    const contratListe = ref([])
    const retraitListe = ref([])
    const homeHeader = ref([{
        value: "N° Contrat Comp",
        text: 'Contrat',
    }, {
        value: 'Societe',
        text: 'Societe',
    }, {
        value: 'LocdataS_::ID_Grille_nom',
        text: 'Type',
    }, {
        value: 'LocdataS_::Date Debut Loc',
        text: 'Début',
    }, {
        value: 'LocdataS_::Date retour',
        text: 'Fin',
    }, {
        value: 'LocdataS_::N° Mat interne',
        text: 'Matricule',
    }, {
        value: 'facturation',
        text: 'Contrat terminé',
    }])

    const getContratsListe = function() {
        engine.get_contrats(function(data) {
            contratListe.value = data.data.data.map((val) => val.fieldData)
            for (let i = 0; i < contratListe.value.length; i++) {
                contratListe.value[i]['facturation'] = contratListe.value[i]['Date facturation']
            }
        })
    }

    const getClientListe = function() {
        engine.get_clients(function(data) {
            if (data.code == 0) {
                clientListe.value = data.data.data.map((val) => val.fieldData)
            }
        })
    }

    const getFamilyList = function() {
        engine.get_family_list(function(data) {
            if (data.code == 0) {
                familyListe.value = Object.values(data.data.data).map((val) => val[0])
            }
        })
    }

    const getSubFamilyList = function() {
        engine.get_sub_family_list(function(data) {
            if (data.code == 0) {
                subFamilyListe.value = data.data.data.data
            }
        })
    }

    const getRetraitList = function() {
        engine.get_retrait_list(function(data) {
            if (data.code == 0) {
                retraitListe.value = data.data.data.map((val) => val.fieldData)
            }
        })
    }

    // const getMaterialListe = function() {
    //     engine.get_material_liste(function(data) {
    //         // if (data.code == 0) {
    //         console.log("getMaterialListe", data);
    //         // subFamilyListe.value = data.data.data.data
    //         // }
    //     })
    // }

    const getContratInformations = function() {
        engine.get_contrat_informations(function(data) {
            if (data.code == 0) {
                contrat_informations.value = data.data.data
            }
        })
    }

    const getParcListe = function() {
        engine.get_parc(function(data) {
            if (data.code == 0) {
                parcListe.value = data.data.data.map((val) => val.fieldData)
            }
        })
    }

    const get_user_information = computed(function() {
        return store.state.user;
    })

    onMounted(() => {
        getContratsListe()
        getClientListe()
        getParcListe()
        getFamilyList()
        getSubFamilyList()
        getContratInformations()
        getRetraitList()
            // getMaterialListe()
    })

    return { getContratsListe, contratListe, homeHeader, clientListe, parcListe, familyListe, subFamilyListe, retraitListe, contrat_informations, get_user_information };
}