<template>
  <div class="planning-gestion-rdv-velo">
    <v-dialog v-model="isModalCreatDispoOpen" v-if="isModalCreatDispoOpen" width="500" max-width="90%">
      <modal-light-gestion :modalData="modalData" :rdvTypeListe="rdvTypeListe" :updateDispo="updateDispo"
        @close="isModalCreatDispoOpen = false"></modal-light-gestion>
    </v-dialog>
    <v-dialog v-model="isModalsOpen['settingsModal'].value" persistent width="1400" max-width="90%" align="center">
      <modal-settings :eventColorByType="eventColorByType" :daysToHide="daysToHide"
        @closeSettingsModal="closeSettingsModal"></modal-settings>
    </v-dialog>
    <v-dialog v-model="isOpen" align="center" fullscreen persistent>
      <modal-gestion :agencyList="agencyList" :isAdmin="isAdmin" @closeModal="isOpen = false"
        :rdvTypeListe="rdvTypeListe"></modal-gestion>
    </v-dialog>
    <v-dialog v-model="isModalDataOpen" width="90%" max-width="800">
      <modal-event :rdvTypeListe="rdvTypeListe" :agencyList="agencyList" :resetFunction="updateDispo"
        :modalData="modalData" :isAdmin="isAdmin" @closeModal="isModalDataOpen = false">
      </modal-event>
    </v-dialog>
    <v-dialog v-model="isAgencySelectorOpen" style="width: 90%; max-width: 500px">
      <modal-agence-selection :isAdmin="isAdmin" :agencyList="agencyList" :selectedAgency="selectedAgency"
        @closeModal="isAgencySelectorOpen = false" @updateAgency="updateAgency($event)"></modal-agence-selection>
    </v-dialog>
    <v-dialog v-model="isModalsOpen['calendarModal'].value" persistent align="center" width="auto">
      <v-card width="auto">
        <DatePicker class="my-datepicker" :timePicker="false" inline :format="'yyyy-MM-dd'" :modelType="'timestamp'"
          locale="fr" :enableTimePicker="false" v-model="calendarDate" autoApply></DatePicker>
        <v-btn color="#EF5350" style="color:white;border-radius: 0px;"
          @click="isModalsOpen['calendarModal'].value = false">Fermer</v-btn>
      </v-card>
    </v-dialog>
    <div align="center" v-if="isLoading == false">
      <div style="position: absolute; top: 14px; right: 60px;z-index: 2">
        <v-icon v-if="isAdmin && isSmallScreen" class="mr-2" @click="isOpen = !isOpen" style="font-size: 35px"> mdi-cog
        </v-icon>
        <v-icon @click="updateDispo()" class="mr-2" style="font-size: 35px"> mdi-refresh </v-icon>
      </div>
      <v-col>
        <v-row>
          <v-col md="3" lg="2" cols="12" class="pa-1 px-2 pb-0" v-if="isSmallScreen == false">
            <!-- <v-btn variant="flat" style="width: 100%; font-size: 60%" class="" @click="isAgencySelectorOpen = true"
              color="white">
              Modifier le(s) agence(s) sélectioné(s)
            </v-btn> -->
            <v-card style="background-color:white" class="px-2 pt-2" variant="flat">
              <v-select label="Agence(s) sélectionné" :items="agencyList" v-model="selectedAgency" multiple return-object
                item-title="WEB_LABEL" variant="outlined" density="compact" hide-details>
                <template v-slot:selection="{ item, index }">
                  <div align="center">
                    <v-chip density="" v-if="index < 3">
                      {{ item.title }}
                    </v-chip>
                    <span v-if="index == 2" class="text-grey text-caption align-self-center">
                      (+{{ selectedAgency.length - 3 }} autres)
                    </span>
                  </div>
                </template>
              </v-select>
              <v-checkbox v-model="selectedAgencyBox" label='Tout séléctionner / déséléctionner' hide-details
                density="compact"></v-checkbox>
            </v-card>
            <v-col class="py-1 px-0">
              <v-btn-toggle style="border:solid 1px lightgrey;width:100%" required v-model="selectedView" color="#5C6BC0">
                <v-btn v-for="(btn, key, idx) in viewList" :key="key" @click="setCalendarView(key)"
                  :style="idx == selectedView ? 'color:white' : ''" style="width:33.33%">
                  {{ btn.buttonText }}
                </v-btn>
              </v-btn-toggle>
            </v-col>
            <DatePicker style="" class="my-datepicker" calendarClassName="my-datepicker_calendar" :clearable="false"
              :timePicker="false" :format="'yyyy-MM-dd'" :modelType="'timestamp'" locale="fr" :enableTimePicker="false"
              v-model="calendarDate" autoApply>
              <template #dp-input="{ value }">
                <v-card style="cursor:pointer" variant="flat" class="btn">
                  Date séléctionnée <br> {{ getFormatedDate(value, 'DD/MM/YYYY') }}
                  <!-- <input type="text" :value="value" /> -->
                </v-card>
              </template>
            </DatePicker>
            <v-card class="pa-2 mt-1" variant="flat" style="border: solid 1px lightgrey">
              <div style="font-weight:bold" class="mb-2">
                ADMINISTRATION
              </div>
              <v-row>
                <v-col cols="12" class="py-1" v-if="isAdmin">
                  <v-btn  width="100%" variant="flat" color="#9E9E9E" style="color:white;padding:0px;"
                    @click="isModalsOpen['settingsModal'].value = true" stacked density="compact">
                      Paramètre du calendrier
                  </v-btn>
                </v-col>
                <v-col cols="12" class="pt-1">
                  <v-btn width="100%" color="#5C6BC0" variant="flat" style="color:white" @click="isOpen = !isOpen" stacked density="compact">
                    Gestion des disponibilités
                  </v-btn>
                </v-col>
              </v-row>
            </v-card>
            <v-card class="pa-2 mt-1" color="white">
              <div style="font-weight:bold" class="mb-1">
                CODE COULEUR
              </div>
              <v-row class="pa-3">
                <v-col v-for="(value, key) in eventColorByType" :key="key" class="pa-0" cols="" style="margin:1px 0px">
                  <div :style="{ 'background-color': value.color }" style="
            font-size: 15px;
            width: 100%;
            padding: 5px;
            border-style: none;
            color: black;
          ">
                    <v-chip style="
              background-color: rgb(0, 0, 0, 0.5);
              color: white;
              cursor: pointer;
            ">
                      {{ value.label }}
                    </v-chip>
                  </div>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
          <v-col class="pa-1 pt-1" md="12" lg="10" cols="12" style="border-left:solid 1px lightgrey">
            <div class="">
              <v-card class="pa-2">
                <full-calendar style="height: 90vh;" id="calendar" ref="fullCalendar" :options="calendarOptions">
                </full-calendar>
              </v-card>
            </div>
          </v-col>
        </v-row>
      </v-col>
      <div v-if="isSmallScreen == true" style="position: absolute; bottom: 2vh; right: 35px; z-index: 1">
        <div style="
          background-color: #e0e0e0;
          border-style: none;
          padding: 5px;
          border-radius: 10px;
          box-shadow: 1px 1px 10px 2px grey;
        ">
          <button v-for="(value, key) in eventColorByType" :key="key" :style="{ 'background-color': value.color }" style="
            font-size: 15px;
            margin: 5px;
            border: solid 1px grey;
            padding: 10px;
            border-style: none;
            border-radius: 5px;
            color: black;
          ">
            <v-chip style="
              background-color: rgb(0, 0, 0, 0.5);
              color: white;
              cursor: pointer;
            ">
              {{ value.label }}
            </v-chip>
          </button>
        </div>
      </div>

    </div>
    <div v-else style="
        position: absolute;
        height:100%;
        width: 100%;
        top:0px;
        background-color:rgb(0,0,0,0.5)
        z-index: 3;
        padding-top:40vh;" align="center">
      <v-progress-circular size="120" indeterminate color="black">Chargement</v-progress-circular>
    </div>

  </div>
</template>

<script>
import "@fullcalendar/core/vdom";
import FullCalendar from "@fullcalendar/vue3";
import calendarData from "../hooks/calendar";
import { ref } from "@vue/reactivity";
import ModalGestion from "./modal-gestion.vue";
import ModalEvent from "./model-event.vue";
import ModalAgenceSelection from "./modal-agence-selection.vue";
import ModalSettings from "@/components/modal-planning-settings.vue";
import engine from "../core/dataApi";
import ModalLightGestion from './modal-light-gestion.vue';
import moment from 'moment'

export default {
  setup() {
    const modalData = ref({});
    const isModalDataOpen = ref(false);
    const isModalCreatDispoOpen = ref(false)

    const handleEventClick = function (event) {
      let info = event.event._def;
      let dates = { start: event.event._def.extendedProps.data.DEBUT, end: event.event._def.extendedProps.data.FIN };
      modalData.value = {
        info,
        dates,
        agence: info.extendedProps.resourceName,
      };
      isModalDataOpen.value = true;
    };

    const handleDateClick = function (event) {
      let date = event.dateStr
      let info = event.resource._resource.extendedProps

      modalData.value = {
        info,
        dates: date,
      };
      isModalCreatDispoOpen.value = true;
      // let info = event.event._def;
      // let dates = { start: event.event.startStr, end: event.event.endStr };
      // modalData.value = {
      //   info,
      //   dates,
      //   agence: info.extendedProps.resourceName,
      // };
      // isModalDataOpen.value = true;
    };

    const {
      calendarOptions,
      selectedAgency,
      agencyList,
      isAdmin,
      updateDispo,
      isLoading,
      eventColorByType,
      isModalsOpen,
      daysToHide,
      rdvTypeListe,
      viewList,
      calendarDate,
      setNewView
    } =
      calendarData(handleEventClick, handleDateClick);

    return {
      calendarOptions,
      selectedAgency,
      agencyList,
      isAdmin,
      isModalDataOpen,
      modalData,
      updateDispo,
      isLoading,
      eventColorByType,
      isModalsOpen,
      daysToHide,
      rdvTypeListe,
      viewList,
      calendarDate,
      setNewView,
      isModalCreatDispoOpen
    };
  },
  watch: {
    selectedAgencyBox(val) {
      if (val) {
        this.selectedAgency = this.agencyList
      } else {
        this.selectedAgency = []
      }
    },
    calendarDate(val) {
      if (val != undefined && this.$refs.fullCalendar) {
        let calendarApi = this.$refs.fullCalendar.getApi()
        calendarApi.gotoDate(val);
      }
    },
    isOpen(val) {
      if (val == false) {
        this.updateDispo()
      }
    }
  },
  data() {
    return {
      // calendarDate: undefined,
      selectedAgencyBox: true,
      isOpen: false,
      selectedView: 0,
      isAgencySelectorOpen: false,
    };
  },
  components: {
    FullCalendar,
    ModalGestion,
    ModalEvent,
    ModalAgenceSelection,
    ModalSettings,
    ModalLightGestion
  },
  computed: {
    isSmallScreen() {
      return this.$vuetify.display.mdAndDown
    }
  },
  methods: {
    getFormatedDate(date, format) {
      return moment(date).format(format);
    },
    setCalendarView(view) {
      let calendarApi = this.$refs.fullCalendar.getApi()
      calendarApi.changeView(view);
      this.setNewView(view)
    },
    closeModal(modalKey) {
      this.isModalsOpen[modalKey].value = false;
    },
    closeSettingsModal(data) {
      let that = this;
      let days = data.daysListe.filter(
        (val) => !data.daysToShow.includes(val.idx)
      );
      if (days) {
        days = days.map((val) => val.idx);
        engine.save_day_to_hide(days.toString(), function () {
          that.updateDispo();
        });
      }
      engine.save_calendar_colors(
        JSON.stringify(data.localColortab),
        function () {
          that.updateDispo();
        }
      );
      this.closeModal("settingsModal");
    },
    updateAgency(newAgency) {
      this.selectedAgency = newAgency;
    },
  },
  mounted() {
    // this.calendarDate = parseInt(moment().format('x'))
  },
};
</script>


<style scoped>
@import "../style.css";
</style>