import axios from "axios";
var CryptoJS = require("crypto-js");

class planningLivraison {
    constructor() {
        this.URL = "https://" + process.env.VUE_APP_MAIN_URL + "/api/planningLivraison";
        this.cryptageKey = "2687da56-4181-48a6-bec4-c526727423f8"
    }

    encrypt(datas) {
        return { data: CryptoJS.AES.encrypt(JSON.stringify(datas), this.cryptageKey).toString() }
    }

    decrypt(data) {
        var bytes = CryptoJS.AES.decrypt(data, this.cryptageKey);
        var originalData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        return originalData
    }

    get_materials(start, end, callback) {
        var datas = { start, end };
        axios.post(this.URL + '/v1/get_materials', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    set_technicien_available_liste_by_key(key, data, callback) {
        let datas = { key, data }
        axios.post(this.URL + '/v1/set_technicien_available_liste_by_key', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    get_technicien_available_liste(callback) {
        let datas = {}
        axios.post(this.URL + '/v1/get_technicien_available_liste', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    get_technicien_liste(callback) {
        let datas = {}
        axios.post(this.URL + '/v1/get_technicien_liste', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    get_contrat_from_num_contrat(numContrat, callback) {
        let datas = { numContrat }
        axios.post(this.URL + '/v1/get_contrat_from_num_contrat', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    get_societe_by_id(id, callback) {
        let datas = { id }
        axios.post(this.URL + '/v1/get_societe_by_id', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    set_livraison_retour_comment(idEnregistrement, data, callback) {
        let datas = { idEnregistrement, data }
        axios.post(this.URL + '/v1/set_livraison_retour_comment', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    get_agency(callback) {
        let datas = {}
        axios.post(this.URL + '/v1/get_agency', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    get_clients(callback) {
        let datas = {}
        axios.post(this.URL + '/v1/get_clients', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    get_technicien_liste_to_show(callback) {
        let datas = {}
        axios.post(this.URL + '/v1/get_technicien_liste_to_show', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    set_technicien_liste_to_show(liste, token, callback) {
        let datas = { liste, token }
        axios.post(this.URL + '/v1/set_technicien_liste_to_show', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    get_user_by_token(token, callback) {
        let datas = { token }
        axios.post(this.URL + '/v1/get_user_by_token', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    account_login(username, password, callback) {
        let datas = { username, password }
        axios.post(this.URL + '/v1/account_login', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }
    save_calendar_colors(colors, callback) {
        let datas = { colors }
        axios.post(this.URL + '/v1/save_calendar_colors', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    get_calendar_colors(callback) {
        let datas = {}
        axios.post(this.URL + '/v1/get_calendar_colors', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }


    save_day_to_hide(days, callback) {
        let datas = { days }
        axios.post(this.URL + '/v1/save_day_to_hide', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    get_day_to_hide(callback) {
        let datas = {}
        axios.post(this.URL + '/v1/get_day_to_hide', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

}

export default new planningLivraison()