<template>
    <div v-if="showApp">
        <page-sav-gestion></page-sav-gestion>
    </div>
</template>
  
<script>

import PageSavGestion from "@/projects/sav-gestion/components/page-home.vue";

export default {
    data() {
        return {
            showApp: false,
        };
    },
    mounted() {
        let appsToShow = this.$store.state.webApplicationToShow;
        let currentApp = this.$route.fullPath.replace("/", "");
        if (appsToShow.includes(currentApp)) this.showApp = true;
    },
    components: {
        PageSavGestion,
    },
};
</script>
  
<style></style>