<template>
  <div>
    <v-toolbar dark color="primary">
      <v-toolbar-title>Modifier le formulaire</v-toolbar-title>
    </v-toolbar>
    <v-row justify="center" class="" align="center">
      <v-col cols="12" v-if="isAdmin">
        <v-card
          variant="outlined"
          style="border-radius: 0px; border: solid 1px lightgrey"
        >
          <v-table dense style="height: 63vh; overflow: auto">
            <thead>
              <tr>
                <th class="text-left">Valeure</th>
                <th class="text-center">Afficher</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, idx) in personalizedData"
                :key="item['Clé primaire']"
              >
                <td>{{ item.name }}</td>
                <td>
                  <v-switch
                    inset
                    style="padding-left: 40%"
                    color="green"
                    v-model="personalizedData[idx]['show']"
                    hide-details
                  ></v-switch>
                </td>
              </tr>
            </tbody>
          </v-table>
        </v-card>
      </v-col>
      <v-col cols="12" class="px-1">
        <v-card-actions align="right">
          <v-btn color="red" style="color: white" @click="close()"
            >Annuler</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="updateUser()">Mettre à jour</v-btn>
        </v-card-actions>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import engine from "../core/index";

export default {
  data() {
    return {
      dataForm: {},
      personalizedData: undefined,
      form: [
        {
          label: "Nom d'utilisateur",
          type: "text",
          key: "user_name",
          error: false,
        },
        {
          label: "Agence",
          type: "select",
          key: "user_id_agence",
          error: false,
        },
      ],
    };
  },
  props: {
    isAdmin: {},
    agencyList: {},
    user_information: {},
  },
  mounted() {
    let that = this;
    // this.dataForm = JSON.parse(JSON.stringify(this.user_information));
    // this.dataForm.user_id_agence = parseInt(this.dataForm.user_id_agence)
    engine.get_form_special_data(function (data) {
      if (data.code == 0) {
        that.personalizedData = data.data.data.map((val) => val.fieldData);
        for (let i = 0; i < that.personalizedData.length; i++) {
          that.personalizedData[i].show = that.personalizedData[i].show == 1;
        }
      }
    });
  },
  computed: {
    // getFormManagerData(){
    //   return
    // },
    getAgencyList() {
      let list = [];
      for (let i = 0; i < this.agencyList.length; i++) {
        list.push({
          text: this.agencyList[i].data.ag_nom,
          value: this.agencyList[i].data.ID_Agence,
        });
      }
      return list;
    },
  },
  methods: {
    updateUser() {
      let that = this;
      for (let i = 0; i < this.personalizedData.length; i++) {
        engine.set_form_special_data(
          {
            "Clé primaire": this.personalizedData[i]["Clé primaire"],
            show: this.personalizedData[i].show == true ? 1 : 0,
          },
          function () {}
        );
      }
      engine.edit_user_info(this.dataForm, function () {
        that.$emit("updateUser");
        that.$emit("close");
      });
    },
    close() {
      this.dataForm = {};
      this.$emit("close");
    },
  },
};
</script>

<style>
</style>