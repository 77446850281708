<template>
  <v-card style="background-color: #C8E6C9;">
    <v-col>
      <v-card class="pa-2">
        <div style="margin-bottom: 10px">
          <div align="center">
            <h2>Liste des techniciens à afficher</h2>
          </div>
          <v-text-field class="mb-3" variant="outlined" type="text" v-model="filters" placeholder="Filtre" hide-details
            density="compact"></v-text-field>
          <v-col>
            <v-row>
              <v-col v-for="tech in technicienListToShow" :key="tech.id" @click="addOrRmvTechnicien(tech.title)"
                cols="auto">
                <div class="colorHover">
                  <input type="checkbox" :checked="selectedTechniciens.includes(tech.title)" />
                  {{ tech.title }}
                </div>
              </v-col>
            </v-row>
          </v-col>
          <v-btn style="
            background-color: #9e9e9e;
            color: white;
          " @click="setSelectedTechniciens">
            Tout Séléctionner/Déselectionner
          </v-btn>
        </div>
      </v-card>
    </v-col>
    <v-col v-if="isRoot">
      <v-card class="pa-2">
        <div>
          <div align="center">
            <h2 style="margin-top: 10px">
              Liste des indisponibilités
              <v-btn color="#C8E6C9" icon="mdi-plus"  density="compact" v-if="isEditionOpen == false" @click="isEditionOpen = true">
              </v-btn>
            </h2>
            <div v-if="isEditionOpen" style="
                border: solid 1px lightgrey;
                border-radius: 5px;
                padding: 10px;
                margin: 10px 5px;
              ">
              <tr>
                <th>
                  <div style="margin: 0px 5px">
                    Début<Datepicker v-model="dateStart" locale="fr" format="dd/MM/yyyy HH:mm" :clearable="false"
                      :cancelText="'Fermer'" :selectText="'Valider'"></Datepicker>
                  </div>
                </th>
                <th>
                  <div style="margin: 0px 5px">
                    Fin<Datepicker v-model="dateEnd" locale="fr" format="dd/MM/yyyy HH:mm" :clearable="false"
                      :cancelText="'Fermer'" :selectText="'Valider'"></Datepicker>
                  </div>
                </th>
                <th>
                  <div style="margin: 0px 5px">
                    Technicien
                    <v-select variant="outlined" hide-details density="compact" v-model="selectedTech"
                      :items="technicienList" style="
                        width: 100%;
                        height: 35px;
                        border-color: lightgrey;
                        border-radius: 3px;
                      ">
                      <!-- <option v-for="tech in technicienList" :key="tech.id">
                        {{ tech.title }}
                      </option> -->
                    </v-select>
                  </div>
                </th>
                <th>
                  <div style="margin: 0px 5px">
                    Type
                    <v-select variant="outlined" hide-details density="compact" v-model="selectedType" :items="typeList"
                      style="
                        width: 100%;
                        border-color: lightgrey;
                        border-radius: 3px;
                      ">
                      <!-- <option v-for="type in typeList" :key="type">
                        {{ type }}
                      </option> -->
                    </v-select>
                  </div>
                </th>
              </tr>
              <div style="padding-top: 10px">
                <v-btn class="button-state" style="
                    background-color: #2e7d32;
                    font-size: 15px;
                    padding: 5px;
                    margin: 2px;
                    color: white;
                    cursor: pointer;
                  " @click="creatNotDispo()">
                  Ajouter
                </v-btn>
                <v-btn class="button-state" style="
                    background-color: #d84315;
                    font-size: 15px;
                    padding: 5px;
                    margin: 2px;
                    color: white;
                    cursor: pointer;
                  " @click="cancelCreationDispo()">
                  Annuler
                </v-btn>
              </div>
            </div>
          </div>
        </div>
        <div style="
            overflow: auto;
            height: 200px;
            border: solid 1px lightgrey;
            padding: 10px;
            margin: 5px;
            border-radius: 5px;
          ">
          <table style="width: 100%">
            <thead style="background-color: #f5f5f5">
              <tr align="center">
                <th>Début</th>
                <th>Fin</th>
                <th>Technicien</th>
                <th>Type</th>
                <th v-if="isRoot"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="tech in getTechnicienListAvailable" :key="tech['Clé primaire']">
                <td>{{ tech.DEBUT }}</td>
                <td>{{ tech.FIN }}</td>
                <td>{{ tech.TECHNICIEN }}</td>
                <td>{{ tech.TYPE }}</td>
                <td v-if="isRoot">
                  <v-btn variant="tonal" style="color: red; font-size: 20px; cursor: pointer" @click="removeNotDispo(tech)">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </v-card>
    </v-col>
    <div align="center">
      <v-btn class="mb-3 mt-1" style="
            background-color: red;
            color: white;
            border-style: none;
            padding: 5px;
            border-radius: 5px;
            font-size: 16px;
            cursor: pointer;
          " @click="closeModal">
        Fermer
      </v-btn>
    </div>
  </v-card>
</template>

<script>
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import planningTechnicien from "../core/index";
import moment from "moment";

export default {
  data() {
    return {
      typeList: [
        "CP ( congé payé )",
        "RTT",
        "CSS ( congé sans solde )",
        "AEX ( absence exceptionnelle )",
        "CM ( congé maladie )",
        "Autre",
      ],
      isEditionOpen: false,
      dateStart: "",
      dateEnd: "",
      selectedTech: "",
      selectedType: "",
      filters: "",
    };
  },
  components: {
    Datepicker,
  },
  props: {
    isRoot: {},
    technicienList: {},
    technicienListAvailable: {},
    selectedTechniciens: {},
    addOrRmvTechnicien: {},
    setSelectedTechniciens: {},
    setTechnicienAvailable: {},
    updateTechToShow: {},
  },
  mounted() {
    let that = this;
    planningTechnicien.getIndisponibilityType(function (data) {
      if (data.code == 0) {
        that.typeList = data.data.data.map((val) => val.fieldData.Name);
      }
    });
  },
  watch: {
    isEditionOpen(val) {
      if (val == true) {
        this.dateStart = moment()
          .set({ hour: 8, minute: 0 })
          .format("MM/DD/YYYY HH:mm");
        this.dateEnd = moment()
          .set({ hour: 18, minute: 0 })
          .format("MM/DD/YYYY HH:mm");
        this.selectedType = this.typeList[0];
        this.selectedTech = this.technicienList[0].title;
      }
    },
  },
  computed: {
    getTechnicienListAvailable() {
      let list = [];
      for (let i = 0; i < this.technicienListAvailable.length; i++) {
        let value = this.technicienListAvailable[i];
        value.DEBUT = moment(value.start).format("DD/MM/YYYY HH:mm");
        value.FIN = moment(value.end).format("DD/MM/YYYY HH:mm");
        list.push(value);
      }
      return list;
    },
    technicienListToShow() {
      let techList = this.technicienList;
      techList = techList.sort(function (a, b) {
        if (a.id < b.id) return -1;
        if (a.id > b.id) return 1;
        return 0;
      });

      if (this.filters == "") {
        return techList;
      } else {
        let filteredTech = [];
        for (let i = 0; i < techList.length; i++) {
          if (techList[i].id.toLowerCase().includes(this.filters.toLowerCase()))
            filteredTech.push(techList[i]);
        }
        return filteredTech;
      }
    },
  },
  methods: {
    creatNotDispo() {
      let data = {
        DEBUT: moment(this.dateStart).format("MM/DD/YYYY HH:mm"),
        FIN: moment(this.dateEnd).format("MM/DD/YYYY HH:mm"),
        TECHNICIEN: this.selectedTech,
        TYPE: this.selectedType,
        state: 1,
      };
      let that = this;
      planningTechnicien.set_technicien_available_liste_by_key(
        "",
        data,
        function () {
          that.setTechnicienAvailable();
        }
      );
      this.cancelCreationDispo();
    },
    removeNotDispo(data) {
      let that = this;
      planningTechnicien.set_technicien_available_liste_by_key(
        data["Clé primaire"],
        { state: 0 },
        function () {
          that.setTechnicienAvailable();
        }
      );
    },
    cancelCreationDispo() {
      this.isEditionOpen = false;
      this.dateStart = undefined;
      this.dateEnd = undefined;
      this.selectedTech = undefined;
      this.selectedType = undefined;
    },
    closeModal() {
      this.updateTechToShow();
      this.$emit("close");
    },
    saveModal() {
      this.$emit("save", {
        e: this.data.e,
        Techniciens_Nom: this.data.calendarTech,
      });
    },
  },
};
</script>

<style>
.planning-technicien .colorHover:hover {
  background-color: lightgrey;
}
</style>