<template>
  <v-card>
    <v-item-group :multiple="isAdmin" v-model="localSelectedAgency">
      <v-col>
        <v-row class="px-0 pt-0 mb-1">
          <v-col align="center" class="px-0 pt-0">
            <v-card
              class="pa-2"
              color="#5C6BC0"
              variant="flat"
              style="border-radius: 0px; color: white"
            >
              <h2>LISTE DES AGENCES</h2>
            </v-card>
          </v-col>
        </v-row>
        <div
          style="border: solid 1px lightgrey; border-radius: 5px"
          class="pa-2"
        >
          <div style="font-weight: bold">
            Sélectioné les agences que vous souhaitez afficher
          </div>
          <v-divider class="mt-1"></v-divider>
          <v-row class="pt-1 ma-2" style="max-height: 400px; overflow: auto">
            <v-col
              v-for="agency in agencyList"
              :key="agency.id"
              cols="12"
              class=""
            >
              <v-item v-slot="{ isSelected, toggle }" :value="agency">
                <v-card
                  align="center"
                  :color="!isSelected ? '#E0E0E0' : ''"
                  class="pa-1"
                  variant="outlined"
                  @click="toggle"
                >
                  {{ agency.WEB_LABEL }}
                </v-card>
              </v-item>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-item-group>
    <v-col class="pa-2">
      <v-btn
        width="100%"
        variant="flat"
        color="#E57373"
        style="color: white"
        @click="close()"
        >Fermer</v-btn
      >
    </v-col>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      localSelectedAgency: [],
    };
  },
  props: {
    isAdmin: {},
    agencyList: {},
    selectedAgency: {},
  },
  mounted() {
    this.localSelectedAgency = this.selectedAgency;
  },
  methods: {
    close() {
      this.$emit("closeModal");
    },
  },
  watch: {
    selectedAgency(val) {
      this.localSelectedAgency = val;
    },
    localSelectedAgency(val) {
      this.$emit("updateAgency", val);
    },
  },
};
</script>

<style>
</style>