<template>
  <div class="planning-disponibilite">
    <v-card style="width: 800px; font-size: 19px" class="pa-5">
      <v-col>
        <v-row align="center">
          <v-col>
            <div align="center" style="font-weight: 600">Sous Famille</div>
            <div
              style="
                background-color: #616161;
                color: white;
                border: solid 1px lightgrey;
                padding: 5px;
                border-radius: 5px;
                font-weight: 600;
              "
              align="center"
            >
              {{ data.resource.extendedProps.subFamily }}
            </div>
          </v-col>
          <v-col>
            <div align="center" style="font-weight: 600">Matricule</div>
            <div
              align="center"
              style="
                background-color: white;
                border: solid 1px lightgrey;
                padding: 5px;
                border-radius: 5px;
                font-weight: 600;
              "
            >
              {{ data.resource.extendedProps.matricule }}
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col align="center">
            <v-btn-toggle
              class="pa-1"
              style="border: solid 1px grey; width: 100%"
              v-model="selectedType"
              tile
              color="deep-purple accent-3"
              group
            >
              <v-btn
                style="width: 50%; color: black"
                :color="eventColorByType.LOCATION"
                value="location"
              >
                <h4>Location</h4>
              </v-btn>
              <v-btn
                style="width: 50%; color: black"
                :color="eventColorByType.RESERVATION"
                value="reservation"
              >
                <h4>Réservation</h4>
              </v-btn>
            </v-btn-toggle>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" align="center">
            <date-picker
              :minutesIncrement="10"
              v-model="selectedDates"
              range
              inline
              autoApply
              locale="fr"
            ></date-picker>
          </v-col>
        </v-row>
        <v-col class="px-0">
          <div style="border: solid 1px grey; border-radius: 5px" class="pa-1">
            <v-row justify="center" align="center">
              <v-col v-if="selectedDates[0]" align="center">
                <div class="pa-2">
                  <h4>Début : {{ getFormatedDate(selectedDates[0]) }}</h4>
                </div>
              </v-col>
              <div style="font-weight: bold; font-size: 20px">➨</div>
              <v-col v-if="selectedDates[1]" align="center">
                <div class="pa-2">
                  <h4>Fin : {{ getFormatedDate(selectedDates[1]) }}</h4>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-col>
      <v-col class="py-0">
        <v-row justify="center" align="center">
          <v-col v-if="selectedDates.length != 0" align="center">
            <v-btn
              variant="flat"
              color="#3949AB"
              style="color: white; width: 100%"
              >Finaliser sur Accen</v-btn
            >
          </v-col>
          <v-col align="center">
            <v-btn
              variant="flat"
              color="#FF5722"
              style="color: white; width: 100%"
              @click="closeModal"
              >Fermer</v-btn
            >
          </v-col>
        </v-row>
      </v-col>
    </v-card>
  </div>
</template>
 
<script>
import moment from "moment";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";

export default {
  data() {
    return {
      selectedDates: [],
      selectedType: "location",
    };
  },
  methods: {
    closeModal() {
      this.$emit("closeModal");
    },
    getFormatedDate(date) {
      return moment(date).format("DD/MM/YYYY HH:mm");
    },
  },
  mounted() {
    this.selectedDates = [
      moment(this.data.date)
        .set({ hour: 8, minute: 0, second: 0, millisecond: 0 })
        .toDate(),
      moment(this.data.date)
        .set({ hour: 18, minute: 0, second: 0, millisecond: 0 })
        .toDate(),
    ];
  },
  props: {
    data: {},
    eventColorByType: {},
  },
  components: { datePicker: Datepicker },
};
</script>

<style>
</style>