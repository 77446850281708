<template>
  <div style="background-color: white">
    <div style="border: solid 1px lightgrey; height: 35.2vh; overflow-y: auto" class="pa-1">
      <v-col class="pa-0">
        <v-row class="ma-1 pa-1" style="
            text-transform: uppercase;
            font-weight: bold;
            background-color: white;
            border-radius: 5px;
          " justify="center" align="center">
          <v-col cols="auto" class="pa-1">
            édition de l'itinéraire sélectionné
          </v-col>
          <v-divider vertical class="mx-2 my-1" v-if="editableItineraireValidTab"></v-divider>
          <v-col cols="" class="pa-1">
            <v-row v-if="editableItineraireValidTab &&
              editableItineraireValidTab.id == undefined
              ">
              <v-col>
                <v-btn variant="flat" class="my-0" color="#81C784" style="color: white" width="100%"
                  :disabled="isFormValid(editableItineraireValidTab)"
                  @click="addEvent(editableItineraireValidTab, getItineraireOrderLastVue)">
                  Ajouter au calendrier
                </v-btn>
              </v-col>
              <v-col>
                <v-btn variant="flat" class="my-0" color="#5C6BC0" style="color: white" width="100%"
                  @click="cancelEvent()">
                  Annuler
                </v-btn>
              </v-col>
            </v-row>
            <v-row v-if="editableItineraireValidTab &&
              editableItineraireValidTab.id != undefined
              " justify="center" align="center">
              <v-col class="py-0" cols="">
                <v-btn variant="flat" class="my-0" color="#FFB74D" style="color: white" width="100%"
                  :disabled="isFormValid(editableItineraireValidTab)"
                  @click="editEvent(editableItineraireValidTab, getItineraireOrderLastVue)">
                  Mettre à jour l'évenement
                </v-btn>
              </v-col>
              <v-col>
                <v-btn variant="flat" class="my-0" color="#81C784" style="color: white" width="100%"
                  :disabled="isFormValid(editableItineraireValidTab)"
                  @click="addEvent(editableItineraireValidTab, getItineraireOrderLastVue)">
                  Ajouter au calendrier
                </v-btn>
              </v-col>

              <v-col class="py-0" cols="">
                <v-btn variant="flat" class="my-0" color="#5C6BC0" style="color: white" width="100%"
                  @click="editableItineraireValidTab = undefined">
                  Annuler
                </v-btn>
              </v-col>
              <v-col class="py-0" cols="auto">
                <v-btn size="x-small" icon="mdi-delete" color="#EF5350" style="color: white"
                  @click="deleteEvent(editableItineraireValidTab)">
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
      <v-divider class="mt-2 "></v-divider>
      <v-col v-if="editableItineraireValidTab" class="px-3">
        <v-row justify="center" align="center" class="">
          <v-col v-for="(value, key) in filterItineraireValidTab" :cols="key == 'itineraire' ? '12' : ''" :key="key"
            align="center" class="px-0">
            <v-divider v-if="key == 'itineraire'" class="mb-2"></v-divider>
            <div>
              <div v-if="key != 'itineraire'" style="
                    background-color: white;
                    border-radius: 5px;
                    font-weight: bold;
                    text-transform: uppercase;
                  " class="mx-1">
              </div>
            </div>
            <v-divider class="my-2"></v-divider>
            <v-card variant="text" style="background-color: white" class="mx-1 pa-2"
              :style="key != 'itineraire' ? 'height:55px' : ''">
              <!-- {{ keyTranslator[key] }} -->
              <div v-if="key == 'name'">
                <v-text-field placeholder="Description" v-model="editableItineraireValidTab[key]" density="compact"
                  hide-details variant="outlined"></v-text-field>
              </div>
              <div v-if="key == 'driver'">
                <v-select :label="keyTranslator[key]" style="background-color: white"
                  v-model="editableItineraireValidTab[key]" :items="driversListe" density="compact" variant="outlined"
                  hide-details></v-select>
              </div>
              <!-- <div v-else-if="key == 'type'">
                <v-select style="background-color: white" v-model="editableItineraireValidTab[key]" :items="typeListe"
                  density="compact" variant="outlined" hide-details></v-select>
              </div> -->
              <div v-if="key == 'start'" class="mt-1">
                <date-picker v-model="editableItineraireValidTab.start" :clearable="true"
                  @change="dateChange($event, editableItineraireValidTab)" format="DD/MM/YYYY HH:mm"
                  value-type="timestamp" type="datetime" :show-time-panel="showTimePanel" :placeholder="editableItineraireValidTab.start == ''
                    ? 'Aucune date sélectionnée'
                    : formatDateToShow(editableItineraireValidTab.start)
                    " :minute-step="10" :editable="false">
                  <template #footer>
                    <button class="mx-btn mx-btn-text" @click="toggleTimePanel">
                      {{
                        showTimePanel
                        ? "Sélectionner une date"
                        : "Sélectionner une heure"
                      }}
                    </button>
                  </template>
                </date-picker>
              </div>
              <div v-if="key == 'poid'" class="pt-2">
                {{ keyTranslator[key] }} {{ value[0].max }} Kg
              </div>
              <div v-if="key != 'itineraire'" class="pt-2">
              </div>
              <div v-if="key == 'time' || key == 'distance'" class="pt-2">
                {{ keyTranslator[key] }} {{ value }}
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-divider></v-divider>
      <v-col v-if="editableItineraireValidTab" @click="reloadIteneraire" style="background-color: #EEEEEE;cursor:pointer">
        <v-row style="overflow-x: auto; width: 100%;">
          <v-col v-for="(elem, i) in getItineraireOrderLastVue" :key="i" cols="3" class="pa-1">
            <v-card class="pa-1" variant="text" height="100%"
              style="border:solid 1px #EEEEEE;position:relative;background-color: #BDBDBD;" align=center>
              <v-badge style="position:absolute;top:15px;left:20px;z-index: 2;" :content="i + 1" color="#3E2723">
              </v-badge>
              <v-card style="font-weight:bold;background-color: #F5F5F5;font-size: 13px;" class="pa-1" variant="text">
                {{ elem.clients[0] }}
                <v-icon class="" color=""> mdi-arrow-right-bold </v-icon>
                <br>
                {{ elem.clients[1] }}
                <!-- ( <v-icon>mdi-weight-kilogram</v-icon> {{ filterItineraireValidTab.poid[i].total }} Kg ) -->
                ( <v-icon>mdi-weight-kilogram</v-icon> {{ filterItineraireValidTab.poid[i].currendPoid }} Kg )
              </v-card>
              <div v-if="elem.matriculeListeStock.length > 0">
                <v-card v-for="(mat, a) in elem.matriculeListeStock" :key="a" class="mt-1 pa-1" variant="text"
                  style="font-size:12px;border: solid 1px lightgrey;background-color: white;" align="center">
                  <v-row>
                    <v-col cols="" align="left">
                      <v-icon color="#7986CB">mdi-arrow-up-bold-circle</v-icon>
                    </v-col>
                    <v-col cols="auto" align="right">
                      {{ mat.title }} -
                      {{ mat.CLD_POIDS }}
                    </v-col>
                  </v-row>
                </v-card>
              </div>
              <div v-if="elem.matriculeListeSortie.length > 0">
                <v-card v-for="(mat, a) in elem.matriculeListeSortie" :key="a" class="mt-1 pa-1" variant="text"
                  style="font-size:12px;border: solid 1px lightgrey;background-color: white" align="center">
                  <v-row>
                    <v-col cols="" align="left">
                      <v-icon color="#E57373">mdi-arrow-down-bold-circle</v-icon>
                    </v-col>
                    <v-col cols="auto" align="right">
                      {{ mat.title }} - {{ mat.CLD_POIDS }}
                    </v-col>
                  </v-row>
                </v-card>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </div>
  </div>
</template>

<script>
import DatePicker from "vue-datepicker-next";
import "vue-datepicker-next/index.css";
import "vue-datepicker-next/locale/fr";
import moment from "moment";
import _ from 'lodash'

export default {
  data() {
    return {
      keyTranslator: {
        name: "description",
        start: "Début",
        time: "Temps",
        distance: "Distance",
        poid: "Poids MAX",
        driver: "Chauffeur",
        // type: "Type",
        itineraire: "Trajet(s)",
      },
      editableItineraireValidTab: undefined,
    };
  },
  watch: {
    mainItineraireValidTab(val) {
      this.editableItineraireValidTab = val;
    },
  },
  methods: {
    getMaxPoid(poidList) {
      let max = 0;
      let add = 0;
      for (let i = 0; i < poidList.length; i++) {
        add += poidList[i].total;
        if (add > max) {
          max = add;
        }
      }
      return max;
    },
    getPoids(item) {
      let poidLiv = 0
      let poidRet = 0

      for (let i = 0; i < item.matriculeListeSortie.length; i++) {
        poidLiv += item.matriculeListeSortie[i].CLD_POIDS
      }

      for (let i = 0; i < item.matriculeListeStock.length; i++) {
        poidRet += item.matriculeListeStock[i].CLD_POIDS
      }

      return { poidLiv, poidRet, total: poidRet + poidLiv }
    },
    cancelEvent() {
      this.editableItineraireValidTab = undefined;
      this.addEvent(undefined);
    },
    formatDateToShow(date) {
      return moment(date).format("DD/MM/YYYY HH:mm");
    },
    isFormValid(form) {
      let mandatoryKeys = ["start", "name", "driver"];
      let valid = mandatoryKeys.filter(
        (key) => form[key] == undefined || form[key].length == 0
      );
      if (valid.length == 0) return false;
      return true;
    },
  },
  props: {
    mainItineraireValidTab: {},
    addEvent: {},
    editEvent: {},
    deleteEvent: {},
    showValidatedItineraire: {},
    driversListe: {},
    typeListe: {},
    dateChange: {},
    showTimePanel: {},
    itineraireDataSaved: {},
    toggleTimePanel: {},
    itineraireOrderLastVue: {},
    reloadIteneraire: {},
  },
  components: {
    DatePicker,
  },
  computed: {
    getItineraireOrderLastVue() {
      return _.cloneDeep(this.itineraireOrderLastVue).map((val) => ({ clients: val.clients, matriculeListeSortie: val.matriculeListeSortie, matriculeListeStock: val.matriculeListeStock, poid: val.poid }))
    },
    filterItineraireValidTab() {
      let newtab = JSON.parse(JSON.stringify(this.editableItineraireValidTab));

      newtab['poid'] = this.getItineraireOrderLastVue.map((val) => this.getPoids(val))

      let totalpoidLiv = 0
      let totalpoidRet = 0
      let maxCurrentPoid = 0

      for (let i = 0; i < newtab['poid'].length; i++) {
        totalpoidLiv += newtab['poid'][i].poidLiv
        totalpoidRet += newtab['poid'][i].poidRet
      }

      let tmpPoid = totalpoidLiv
      for (let i = 0; i < newtab['poid'].length; i++) {
        newtab['poid'][i]['totalpoidLiv'] = totalpoidLiv
        newtab['poid'][i]['totalpoidRet'] = totalpoidRet

        if (i == 0)
          newtab['poid'][i]['currendPoid'] = tmpPoid
        else {
          tmpPoid = tmpPoid + newtab['poid'][i - 1].poidRet - newtab['poid'][i - 1].poidLiv
          newtab['poid'][i]['currendPoid'] = tmpPoid
        }

        if (maxCurrentPoid < newtab['poid'][i]['currendPoid'])
          maxCurrentPoid = newtab['poid'][i]['currendPoid']

        newtab['poid'][i]['maxCurrentPoid'] = maxCurrentPoid
        newtab['poid'][i]['max'] = Math.max(totalpoidLiv, totalpoidRet, maxCurrentPoid)
      }

      delete newtab.id;
      delete newtab.minute;

      return newtab;
    },
  },
};
</script>

<style></style>