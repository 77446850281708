import axios from "axios";
var CryptoJS = require("crypto-js");

class DataAPI {
    constructor() {
        this.URL = "https://" + process.env.VUE_APP_MAIN_URL + "/api/etatdl";
        this.cryptageKey = "2687da56-4181-48a6-bec4-c526727423f8"
    }

    encrypt(datas) {
        return { data: CryptoJS.AES.encrypt(JSON.stringify(datas), this.cryptageKey).toString() }
    }

    decrypt(data) {
        var bytes = CryptoJS.AES.decrypt(data, this.cryptageKey);
        var originalData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        return originalData
    }

    photo_exite(dropBoxPath, callback) {
        let datas = { dropBoxPath }

        axios.post(this.URL + '/v1/photo_exite', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json'
            }
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }


    photo_delete(dropBoxPath, callback) {
        let datas = { dropBoxPath }

        axios.post(this.URL + '/v1/photo_delete', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json'
            }
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    photo_download(dropBoxPath, callback) {
        let datas = { dropBoxPath }

        axios.post(this.URL + '/v1/photo_download', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json'
            }
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    file_upload(file, dropBoxPath, callback) {
        let datas = { file, dropBoxPath }

        axios.post(this.URL + '/v1/file_upload', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json'
            }
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    send_pdf_by_email(email, pdf, contrat, agence, photos, callback) {
        let datas = { email, pdf, contrat, agence, photos }
        axios.post(this.URL + '/v1/send_pdf_by_email', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json'
            }
        }).then(res => {
            callback(this.decrypt(res.data));
        });

    }

    send_signature_request(emails, pdf, info, photos, callback) {
        let datas = { emails, pdf, info, photos }
        axios.post(this.URL + '/v1/send_signature_request', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    verif_root_mode_with_password(password, callback) {
        let token = localStorage.getItem('token')
        let datas = { token, password }
        axios.post(this.URL + '/v1/verif_root_mode_with_password', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json'
            }
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    edit_user_info(user, callback) {
        let token = localStorage.getItem('token')
        let datas = { token, user }
        axios.post(this.URL + '/v1/edit_user_info', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json'
            }
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    get_user_info(callback) {
        let token = localStorage.getItem('token')
        let datas = { token }
        axios.post(this.URL + '/v1/get_user_info', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json'
            }
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    user_token_is_valide(token, callback) {
        let datas = { token }
        axios.post(this.URL + '/v1/user_token_is_valide', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json'
            }
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    user_login(user_email, user_password, callback) {
        let datas = { user_email, user_password }
        axios.post(this.URL + '/v1/user_login', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json'
            }
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    user_create_account(user, callback) {
        let datas = { user }
        axios.post(this.URL + '/v1/user_create_account', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json'
            }
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    set_client_state(lien, state, callback) {
        let datas = { lien, state }
        axios.post(this.URL + '/v1/set_client_state', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json'
            }
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    file_download(contrat, matricule, callback) {
        let datas = { contrat, matricule }
        axios.post(this.URL + '/v1/file_download/', this.encrypt(datas), {
            responseType: "arraybuffer",
            credentials: false
        }).then(res => { callback(this.decrypt(res)); }, reserror => { callback(this.decrypt(reserror)); });
    }

    creat_tmp_pdf(id, file, callback) {
        let datas = { id, file }
        axios.post(this.URL + '/v1/creat_tmp_pdf', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json'
            }
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    openPdf(id, callback) {
        let pdfUrl = this.URL + '/v1/Etat_des_lieux_pdf/' + id
        callback(pdfUrl)
    }

    set_photos(contrat, matricule, fileList, type, callback) {
        let datas = { contrat, matricule, fileList, type }
        axios.post(this.URL + '/v1/set_photos', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json'
            }
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    get_photos(contrat, matricule, type, callback) {
        let datas = { contrat, matricule, type }
        axios.post(this.URL + '/v1/get_photos', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json'
            }
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    get_clients(callback) {
        var datas = {};
        axios.post(this.URL + '/v1/get_clients', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    get_agency(callback) {
        var datas = {};
        axios.post(this.URL + '/v1/get_agency', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    get_agency_soft(callback) {
        var datas = {};
        axios.post(this.URL + '/v1/get_agency_soft', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    get_societe_by_id(id, callback) {
        var datas = { id };
        axios.post(this.URL + '/v1/get_societe_by_id', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    get_societe_soft_by_id(id, callback) {
        var datas = { id };
        axios.post(this.URL + '/v1/get_societe_soft_by_id', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    get_materials(callback) {
        var datas = {};
        axios.post(this.URL + '/v1/get_materials', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    get_clients_SS_by_id(id, callback) {
        var datas = { id };
        axios.post(this.URL + '/v1/get_clients_SS_by_id', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    get_depart_materials(callback) {
        var datas = {};
        axios.post(this.URL + '/v1/get_depart_materials', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    get_return_materials(callback) {
        var datas = {};
        axios.post(this.URL + '/v1/get_return_materials', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    get_returned_materials(callback) {
        var datas = {};
        axios.post(this.URL + '/v1/get_returned_materials', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    edit_material(matricule, contrat, id, data, callback) {
        var datas = { matricule, contrat, id, data };
        axios.post(this.URL + '/v1/edit_material', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    get_parcs(matricule, callback) {
        var datas = { matricule };
        axios.post(this.URL + '/v1/get_parcs', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    creat_returned_material(data, callback) {
        var datas = { data };
        axios.post(this.URL + '/v1/creat_returned_material', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    edit_returned_material(recordId, data, callback) {
        var datas = { recordId, data };
        axios.post(this.URL + '/v1/edit_returned_material', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    get_form_special_data(callback) {
        var datas = { isRoot: true };
        axios.post(this.URL + '/v1/get_form_special_data', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    set_form_special_data(data, callback) {
        var datas = { data };
        axios.post(this.URL + '/v1/set_form_special_data', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    get_email_liste_by_id_client(idClient, callback) {
        var datas = { idClient };
        axios.post(this.URL + '/v1/get_email_liste_by_id_client', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    get_default_form(callback) {
        var datas = {};
        axios.post(this.URL + '/v1/get_default_form', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    get_form_by_family(family, callback) {
        var datas = { family };
        axios.post(this.URL + '/v1/get_form_by_family', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    get_form_by_sub_family(subFamily, callback) {
        var datas = { subFamily };
        axios.post(this.URL + '/v1/get_form_by_sub_family', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    get_default_form_values(callback) {
        var datas = {};
        axios.post(this.URL + '/v1/get_default_form_values', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    get_signature_data(id, callback) {
        var datas = { id };
        axios.post(this.URL + '/v1/get_signature_data', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    save_signature(id, content, signataire, callback) {
        var datas = { id, content, signataire };
        axios.post(this.URL + '/v1/save_signature', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    get_headers_table(callback) {
        var datas = {};
        axios.post(this.URL + '/v1/get_headers_table', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    get_options(callback) {
        var datas = {};
        axios.post(this.URL + '/v1/get_options', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    set_tags(dropBoxPath, tags, callback) {
        var datas = { dropBoxPath, tags };
        axios.post(this.URL + '/v1/set_tags', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    send_alert_not_signed(dataForm, username, type, callback) {
        var datas = { dataForm, username, type };
        axios.post(this.URL + '/v1/send_alert_not_signed', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    creat_or_retour(orId, callback) {
        var datas = { orId };
        axios.post(this.URL + '/v1/creat_or_retour', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

}

export default new DataAPI()