import { createRouter, createWebHashHistory } from 'vue-router'

import login from "@/pages/page-login"
import home from "@/pages/page-home"

import pageStatistique from "@/pages/page-projetcs/page-statistique"
import pageEmailVisualisation from "@/pages/page-projetcs/page-email-visualisation"

import planningDisponibilite from "@/pages/page-projetcs/plannings/page-planning-disponibilite"
import planningLivraison from "@/pages/page-projetcs/plannings/page-planning-livraison"
import planningTechnicien from "@/pages/page-projetcs/plannings/page-planning-technicien"
import planningGestionRdv from "@/pages/page-projetcs/plannings/page-planning-gestion-rdv"

import pageEtatDesLieuxMateriel from "@/pages/page-projetcs/page-etat-des-lieux-materiel"
import pageEtatDesLieuxMaterielSignature from "@/projects/etat-des-lieux-materiel/component/redirection-signature"

import gestionLivraison from "@/pages/page-projetcs/page-gestion-livraison"

import pdfSigner from "@/pages/page-projetcs/page-pdf-signer"
import signatureDoubleAuthentification from "@/pages/redirection/signature-double-authentification"

import savGestion from "@/pages/page-projetcs/page-sav-gestion"
import contratGestion from "@/pages/page-projetcs/page-contrat-gestion"

const routes = [
    // { path: '*', component: home },
    { path: '/', component: home },
    { path: '/login', component: login },
    { path: '/planning-disponibilite', component: planningDisponibilite },
    { path: '/planning-livraison', component: planningLivraison },
    { path: '/planning-technicien', component: planningTechnicien },
    { path: '/planning-gestion-rdv-velo', component: planningGestionRdv },
    { path: '/statistiques', component: pageStatistique },
    { path: '/email-visualisation', component: pageEmailVisualisation },
    { path: '/etat-des-lieux-materiel', component: pageEtatDesLieuxMateriel },
    { path: '/etat-des-lieux-materiel/signature', component: pageEtatDesLieuxMaterielSignature },
    { path: '/gestion-livraison', component: gestionLivraison },
    { path: '/signature-en-ligne', component: pdfSigner },
    { path: '/sav-gestion', component: savGestion },
    { path: '/contrat-gestion', component: contratGestion },
    { path: '/signature-double-authentification', component: signatureDoubleAuthentification },
]

const router = createRouter({
    history: createWebHashHistory(),
    routes
})

export default router