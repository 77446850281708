<template>
  <div style="background-color: white" class="pa-1 itinerairemaps">
    <full-calendar :options="calendarOptions" />
  </div>
</template>

<script>
import FullCalendar from "@fullcalendar/vue3";

export default {
  props: {
    calendarOptions: {},
  },
  components: {
    FullCalendar,
  },
};
</script>

<style>
</style>