<template>
  <v-col v-if="!isLoading">
    <v-row>
      <v-col v-for="(title, id) in itemsGroupFiltered" :key="id" cols="12" md="6" class="pa-1">
        <div style="
            border: solid #bdbdbd 3px;
            background-color: white;
            border-radius: 5px;
            height: 100%;
          " class="pa-3 pt-0">
          <v-col cols="12" class="pb-0" align="center">
            <h1 class="my-0 py-0" style="text-transform: uppercase">
              <div>
                {{ title }}
              </div>
            </h1>
          </v-col>
          <v-divider class="mb-3" color="lightgrey"></v-divider>
          <v-row class="pa-1">
            <v-col v-for="elem in getFilteredItem.filter((val) => val.group == id)" :key="elem.href" class="pa-1"
              align="left">
              <v-card :color="elem.color" min-width="100%" max-width="300px" height="100%" style="cursor: pointer"
                class="home-card" @click="goTo(elem.href)">
                <v-col align="center">
                  <v-icon size="80"> {{ elem.icon }} </v-icon> <br />
                  <v-chip style="
                      background-color: #fafafa;
                      border: solid 1px grey;
                      opacity: 0.9;
                    ">
                    <h2>{{ elem.text }}</h2>
                  </v-chip>
                </v-col>
              </v-card>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
  </v-col>
  <div v-else>
    <v-col align="center" style="margin-top: 35vh">
      <v-progress-circular size="120" indeterminate color="black">Chargement</v-progress-circular>
    </v-col>
  </div>
</template>

<script>
import engine from "@/core/index";

export default {
  data() {
    return {
      isLoading: true,
      webApplicationToShow: [],
      itemsGroup: {
        1: "Planning(s)",
        2: "Etat des lieux",
        3: "Autre(s)",
        4: "Gestion",
        5: "Compte rendu",
      },
      items: [
        {
          text: "Disponibilité",
          href: "planning-disponibilite",
          color: "#66BB6A",
          icon: "mdi-calendar-month",
          show: false,
          group: 1,
          showInMobile: true,
          showInTablette: true,
        },
        {
          text: "Livraison",
          href: "planning-livraison",
          color: "#66BB6A",
          icon: "mdi-calendar-month",
          show: false,
          group: 1,
          showInMobile: false,
          showInTablette: true,
        },
        {
          text: "Techniciens",
          href: "planning-technicien",
          color: "#66BB6A",
          icon: "mdi-calendar-month",
          show: false,
          group: 1,
          showInMobile: true,
          showInTablette: true,
        },
        {
          text: "Gestion RDV",
          href: "planning-gestion-rdv-velo",
          color: "#66BB6A",
          icon: "mdi-calendar-month",
          show: false,
          group: 1,
          showInMobile: false,
          showInTablette: true,
        },
        {
          text: "Statistiques",
          href: "statistiques",
          color: "#FFF176",
          icon: "mdi-chart-bar",
          show: false,
          group: 5,
          showInMobile: true,
          showInTablette: true,
        },
        {
          text: "Email visualisation",
          href: "email-visualisation",
          color: "#5C6BC0",
          icon: "mdi-email",
          show: false,
          group: 3,
          showInMobile: true,
          showInTablette: true,
        },
        {
          text: "Materiel",
          href: "etat-des-lieux-materiel",
          color: "#8D6E63",
          icon: "mdi-clipboard-file",
          show: false,
          group: 2,
          showInMobile: true,
          showInTablette: true,
        },
        {
          text: "Vélo",
          href: "etat-des-lieux-velo",
          color: "#8D6E63",
          icon: "mdi-clipboard-file",
          show: false,
          group: 2,
          showInMobile: true,
          showInTablette: true,
        },
        {
          text: "Livraison",
          href: "gestion-livraison",
          color: "#BDBDBD",
          icon: "mdi-map",
          show: false,
          group: 4,
          showInMobile: false,
          showInTablette: true,
        },
        {
          text: "Signature en ligne",
          href: "signature-en-ligne",
          color: "#5C6BC0",
          icon: "mdi-draw",
          show: false,
          group: 3,
          showInMobile: true,
          showInTablette: true,
        }, {
          text: "SAV",
          href: "sav-gestion",
          color: "#BDBDBD",
          icon: "mdi-tools",
          show: false,
          group: 4,
          showInMobile: true,
          showInTablette: true,
        }, {
          text: "Contrat",
          href: "contrat-gestion",
          color: "#BDBDBD",
          icon: "mdi-file-document-outline",
          show: false,
          group: 4,
          showInMobile: true,
          showInTablette: true,
        }
      ],
    };
  },
  mounted() {
    let that = this;
    let token = localStorage.getItem("userToken");
    engine.get_user_by_token(token, function (data) {
      if (data.code == 0) {
        that.webApplicationToShow =
          data.data.data.data[0].fieldData.web_application_to_show.split(",");
        for (let i = 0; i < that.webApplicationToShow.length; i++) {
          that.items.find(
            (val) => val.href == that.webApplicationToShow[i]
          ).show = true;
        }
        that.$store.state.webApplicationToShow = that.webApplicationToShow;
        that.isLoading = false;
      } else {
        that.$router.push("/login");
      }
    });
    this.selectedPage = window.location.pathname.replace("/", "");
  },
  computed: {
    isMobile() {
      return this.$vuetify.display.xs;
    },
    isTablette() {
      return this.$vuetify.display.mdAndDown;
    },
    itemsGroupFiltered() {
      let filteredGroupName = {};
      for (let i = 0; i < this.getFilteredItem.length; i++) {
        filteredGroupName[this.getFilteredItem[i].group] =
          this.itemsGroup[this.getFilteredItem[i].group];
      }
      return filteredGroupName;
    },
    getFilteredItem() {
      if (this.isMobile == true) {
        return this.items.filter((val) => (val.show == true && val.showInMobile == true));
      }
      else if (this.isTablette == true) {
        return this.items.filter((val) => (val.show == true && val.showInTablette == true));
      } else {
        return this.items.filter((val) => val.show == true);
      }
      // else if (this.isMobile == true && this.isTablette == true)
      //   return this.items.filter((val) => val.show == true && val.showInMobile == true);
    },
  },
  methods: {
    goTo(href) {
      this.$router.push(href);
    },
  },
};
</script>

<style>
.home-card {
  padding-top: 10px;
  border: solid 2px transparent;
}

.home-card:hover {
  border: solid 2px #424242;
  filter: brightness(110%);
}
</style>