<template>
  <div class="planning-disponibilite">
    <v-card style="width: 900px; font-size: 19px" class="pa-5" color="#F5F5F5">
      <v-col align="center" class="px-0">
        <div>
          <h3
            style="
              text-transform: uppercase;
              border: solid 1px lightgrey;
              border-radius: 5px;
              background-color: white;
            "
          >
            Service après-vente
          </h3>
        </div>
      </v-col>
      <v-row align="center">
        <v-col>
          <div align="center" style="font-weight: 600">Sous Famille</div>
          <div
            style="
              background-color: #616161;
              color: white;
              border: solid 1px lightgrey;
              padding: 5px;
              border-radius: 5px;
              font-weight: 600;
            "
            align="center"
          >
            {{ event.subFamily }}
          </div>
        </v-col>
        <v-col>
          <div align="center" style="font-weight: 600">Matricule</div>
          <div
            align="center"
            style="
              background-color: white;
              border: solid 1px lightgrey;
              padding: 5px;
              border-radius: 5px;
              font-weight: 600;
            "
          >
            {{ event.matricule }}
          </div>
        </v-col>
      </v-row>
      <v-col>
        <v-row>
          <v-col cols="12" class="px-0">
            <table style="width: 100%; background-color: lightgrey">
              <tr v-for="item in getDataSorted" :key="item.key">
                <td class="td_style">{{ item.key }}</td>
                <td class="td_style">{{ event.eventData[item.key] }}</td>
              </tr>
            </table>
          </v-col>
        </v-row>
        <v-row class="mt-3">
          <v-col align="center" class="px-1">
            <v-btn
              @click="closeModal"
              class="modal-btn"
              style="background-color: red; color: white"
            >
              Fermer
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      event: {},
      keyToShow: [
        { key: "Debut", txt: "Debut" },
        { key: "Fin", txt: "Fin" },
        { key: "MARQUE MACHINE", txt: "Marque" },
        { key: "TYPE MACHINE", txt: "Type" },
        { key: "Theme devis", txt: "Theme devis" },
        { key: "Titre", txt: "Titre" },
        { key: "societeliv", txt: "Societe" },
        { key: "type_travaux", txt: "Type de travaux" },
        { key: "villeliv", txt: "Ville" },
      ],
    };
  },
  mounted() {
    this.event = this.data.event._def.extendedProps;
  },
  computed: {
    getDataSorted() {
      let data = [];
      if (this.event.eventData == undefined) return [];
      for (let i = 0; i < this.keyToShow.length; i++) {
        data.push({
          key: this.keyToShow[i].txt,
          value: this.event.eventData[this.keyToShow[i].key],
        });
      }
      return data;
    },
  },
  methods: {
    closeModal() {
      this.$emit("closeModal");
    },
    // getFormatedDate(date) {
    //   return moment(date).format("DD/MM/YYYY HH:mm");
    // },
  },
  props: {
    data: {},
    eventColorByType: {},
  },
};
</script>

<style>
.planning-disponibilite .td_style {
  padding: 2px 5px;
  background: white;
  font-weight: bold;
  width: 50%;
}
</style>