<template>
  <div align="center" style="position: relative">
    <!-- <h1>ddd</h1> -->
    <div v-if="isLoading" style="position: absolute; padding-top: 20%; width: 100%;">
      <h2 class="my-3">
        Chargement en cours </h2>
      <v-progress-linear style="width: 70%" color="deep-purple accent-4" indeterminate rounded height="6">
      </v-progress-linear>
    </div>
    <qrcode-stream @decode="onDecode" @init="onInit" align="right" style="width:100%;height:100%;">
    </qrcode-stream>
    <p v-if="errorMessage" class="error">
      {{ errorMessage }}
    </p>
  </div>
</template>

<script>
import { QrcodeStream, } from 'qrcode-reader-vue3'

export default {

  data() {
    return {
      errorMessage: undefined,
      isLoading: true,
    };
  },
  props: {
    setMaterialList:Function,
  },
  components: {
    QrcodeStream,
  },
  methods: {
    onDecode(content) {
      let decodedContent = content.split("|-|");
      this.$router.push({
        query: { contrat: decodedContent[0], matricule: decodedContent[1] },
      });
      if (decodedContent.length == 2) this.setMaterialList();
    },

    async onInit(promise) {
      let that = this;
      promise
        .then(() => {
          that.isLoading = false;
        })
        .catch((error) => {
          if (error.name === "NotAllowedError") {
            this.errorMessage = "Hey! I need access to your camera";
          } else if (error.name === "NotFoundError") {
            this.errorMessage = "Do you even have a camera on your device?";
          } else if (error.name === "NotSupportedError") {
            this.errorMessage =
              "Seems like this page is served in non-secure context (HTTPS, localhost or file://)";
          } else if (error.name === "NotReadableError") {
            this.errorMessage =
              "Couldn't access your camera. Is it already in use?";
          } else if (error.name === "OverconstrainedError") {
            this.errorMessage =
              "Constraints don't match any installed camera. Did you asked for the front camera although there is none?";
          } else {
            this.errorMessage = "UNKNOWN ERROR: " + error.message;
          }
          that.isLoading = false;
        });
    },
  },
};
</script>
