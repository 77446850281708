import axios from "axios";
var CryptoJS = require("crypto-js");

class DataApi {
    constructor() {
        // this.URL = "https://test.gestion-livraison.accen.me/api";
        this.URL = "https://" + process.env.VUE_APP_MAIN_URL + "/api/planningLivraison";
        this.cryptageKey = "2687da56-4181-48a6-bec4-c526727423f8"
    }

    encrypt(datas) {
        return { data: CryptoJS.AES.encrypt(JSON.stringify(datas), this.cryptageKey).toString() }
    }

    decrypt(data) {
        var bytes = CryptoJS.AES.decrypt(data, this.cryptageKey);
        var originalData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        return originalData
    }

    get_agency_soft(callback) {
        let datas = {}
        axios.post(this.URL + '/v1/get_agency_soft', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    get_agency(callback) {
        let datas = {}
        axios.post(this.URL + '/v1/get_agency', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    // get_client(callback) {
    //     let datas = {}
    //     axios.post(this.URL + '/v1/get_client', this.encrypt(datas), {
    //         responseType: 'json',
    //         credentials: false,
    //         headers: {}
    //     }).then(res => {
    //         callback(this.decrypt(res.data));
    //     });
    // }

    get_contrat_actif(callback) {
        let datas = {}
        axios.post(this.URL + '/v1/get_contrat_actif', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    get_client_actif(callback) {
        let datas = {}
        axios.post(this.URL + '/v1/get_client_actif', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    addEvent(event, callback) {
        event['supprimer'] = 0
        let datas = { event }
        axios.post(this.URL + '/v1/addEvent', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    editEvent(event, callback) {
        let datas = { event }
        axios.post(this.URL + '/v1/editEvent', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    deleteEvent(event, callback) {
        let datas = { event, supprimer: 1 }
        axios.post(this.URL + '/v1/deleteEvent', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    getEvents(start, end, callback) {
        let datas = { start, end }
        axios.post(this.URL + '/v1/getEvents', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }
}

export default new DataApi()