<template>
  <div class="planning-disponibilite" align="center">
    <v-card max-width="500" width="99%" style="font-size: 17px" color="#EEEEEE">
      <v-col v-if="selectedColor">
        <div align="center">
          <h2>
            {{ selectedColor.key }}
          </h2>
          <v-col>
            <div style="border: solid 1px lightgrey">
              <v-color-picker
                elevation="0"
                v-model="tmpColor"
                width="500"
                :modes="['hexa']"
                :swatches="swatches"
                show-swatches
              ></v-color-picker>
            </div>
          </v-col>
          <v-row>
            <v-col>
              <v-btn
                color="#3949AB"
                style="color: white"
                @click="setNewColor(selectedColor.key, tmpColor)"
              >
                Valider
              </v-btn>
            </v-col>
            <v-col>
              <v-btn
                color="#FF5722"
                style="color: white"
                @click="selectedColor = undefined"
              >
                Annuler
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </v-col>
      <v-col v-else>
        <v-row>
          <v-col align="center">
            <div
              style="
                border: solid 1px lightgrey;
                border-radius: 5px;
                background-color: white;
              "
              class="pa-2"
            >
              <h4 class="mb-1">Jour à afficher</h4>
              <v-btn-toggle
                v-model="daysToShow"
                multiple
                style="border: solid 1px lightgrey; width: 100%"
              >
                <v-btn v-for="day in daysListe" :key="day.idx" :value="day.idx">
                  {{ day.day }}
                </v-btn>
              </v-btn-toggle>
            </div>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col>
            <div
              style="
                border: solid 1px lightgrey;
                border-radius: 5px;
                background-color: white;
              "
              class="pa-4"
            >
              <div v-for="(color, key) in localColortab" :key="key">
                <v-row justify="center" align="center">
                  <v-col cols="6" style="font-weight: bold">
                    {{ color.label }}
                  </v-col>
                  <v-col align="center" cols="6">
                    <div
                      :style="{ 'background-color': color.color }"
                      style="padding: 5px; border-radius: 5px; cursor: pointer"
                      @click="selectedColor = { color, key }"
                    >
                      <v-chip
                        style="
                          background-color: rgb(0, 0, 0, 0.5);
                          color: white;
                          cursor: pointer;
                        "
                      >
                        {{ color.color }}
                      </v-chip>
                    </div>
                  </v-col>
                </v-row>
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col align="center">
            <v-btn
              variant="flat"
              color="#3949AB"
              style="color: white; width: 100%"
              @click="save"
              >Enregistrer</v-btn
            >
          </v-col>
          <v-col align="center">
            <v-btn
              variant="flat"
              color="#FF5722"
              style="color: white; width: 100%"
              @click="closeModal"
              >Fermer</v-btn
            >
          </v-col>
        </v-row>
      </v-col>
    </v-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      daysToShow: [],
      selectedColor: undefined,
      tmpColor: undefined,
      localColortab: {},
      swatches: [
        ["#FF0000", "#AA0000", "#550000"],
        ["#FFFF00", "#AAAA00", "#555500"],
        ["#00FF00", "#00AA00", "#005500"],
        ["#00FFFF", "#00AAAA", "#005555"],
        ["#0000FF", "#0000AA", "#000055"],
      ],
      daysListe: [
        { day: "Lun", idx: 1 },
        { day: "Mar", idx: 2 },
        { day: "Mer", idx: 3 },
        { day: "Jeu", idx: 4 },
        { day: "Ven", idx: 5 },
        { day: "Sam", idx: 6 },
        { day: "Dim", idx: 0 },
      ],
      hiddenDays: [],
    };
  },
  props: {
    eventColorByType: {},
    daysToHide: {},
  },
  mounted() {
    this.localColortab = JSON.parse(JSON.stringify(this.eventColorByType));
    this.daysToShow = this.daysListe
      .filter((val) => !this.daysToHide.includes(val.idx))
      .map((val) => val.idx);
  },
  watch: {
    selectedColor(val) {
      if (val != undefined) {
        this.tmpColor = val.color.color;
      }
    },
  },
  methods: {
    save() {
      this.closeModal();
    },

    setNewColor(key, value) {
      this.localColortab[key].color = value;
      this.selectedColor = undefined;
    },
    closeModal() {
      this.$emit("closeSettingsModal", {
        localColortab: this.localColortab,
        daysToShow: this.daysToShow,
        daysListe:this.daysListe
      });
    },
  },
};
</script>

<style>
</style>