import axios from "axios";
var CryptoJS = require("crypto-js");

class planningDisponibilite {
    constructor() {
        this.URL = "https://" + process.env.VUE_APP_MAIN_URL + "/api/planningDisponibilite";
        this.cryptageKey = "2687da56-4181-48a6-bec4-c526727423f8"
    }

    encrypt(datas) {
        return { data: CryptoJS.AES.encrypt(JSON.stringify(datas), this.cryptageKey).toString() }
    }

    decrypt(data) {
        var bytes = CryptoJS.AES.decrypt(data, this.cryptageKey);
        var originalData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        return originalData
    }

    get_parc(callback) {
        var datas = {};
        axios.post(this.URL + '/v1/get_parc', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    get_events(start, end, callback) {
        var datas = { start, end };
        axios.post(this.URL + '/v1/get_events', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    get_events_reserved(start, end, callback) {
        var datas = { start, end };
        axios.post(this.URL + '/v1/get_events_reserved', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    get_events_sav(start, end, callback) {
        var datas = { start, end };
        axios.post(this.URL + '/v1/get_events_sav', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    get_contrat_from_num_contrat(numContrat, callback) {
        let datas = { numContrat }
        axios.post(this.URL + '/v1/get_contrat_from_num_contrat', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    get_contrat_state(callback) {
        let datas = {}
        axios.post(this.URL + '/v1/get_contrat_state', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });

    }

    get_societe_by_id(id, callback) {
        let datas = { id }
        axios.post(this.URL + '/v1/get_societe_by_id', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    get_societe_by_id_soft(id, callback) {
        let datas = { id }
        axios.post(this.URL + '/v1/get_societe_by_id_soft', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    get_agency(callback) {
        let datas = {}
        axios.post(this.URL + '/v1/get_agency', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    get_agency_soft(callback) {
        let datas = {}
        axios.post(this.URL + '/v1/get_agency_soft', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    get_clients(callback) {
        let datas = {}
        axios.post(this.URL + '/v1/get_clients', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    save_calendar_colors(colors, callback) {
        let datas = { colors }
        axios.post(this.URL + '/v1/save_calendar_colors', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    get_calendar_colors(callback) {
        let datas = {}
        axios.post(this.URL + '/v1/get_calendar_colors', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }


    save_day_to_hide(days, callback) {
        let datas = { days }
        axios.post(this.URL + '/v1/save_day_to_hide', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    get_day_to_hide(callback) {
        let datas = {}
        axios.post(this.URL + '/v1/get_day_to_hide', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }


    get_planning_events_type_to_show(callback) {
        let datas = {}
        axios.post(this.URL + '/v1/get_planning_events_type_to_show', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    user_login(email, password, callback) {
        let datas = { email, password }
        axios.post(this.URL + '/v1/user_login', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    user_registration(user, callback) {
        let datas = { user }
        axios.post(this.URL + '/v1/user_registration', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    get_user_by_token(token, callback) {
        let datas = { token }
        axios.post(this.URL + '/v1/get_user_by_token', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }
}

export default new planningDisponibilite()