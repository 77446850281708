import axios from "axios";
var CryptoJS = require("crypto-js");

class dataApi {
    constructor() {
        this.URL = "https://" + process.env.VUE_APP_MAIN_URL + "/api";
        this.cryptageKey = "2687da56-4181-48a6-bec4-c526727423f8"
    }

    encrypt(datas) {
        return { data: CryptoJS.AES.encrypt(JSON.stringify(datas), this.cryptageKey).toString() }
    }

    decrypt(data) {
        var bytes = CryptoJS.AES.decrypt(data, this.cryptageKey);
        var originalData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        return originalData
    }

    user_login(email, password, callback) {
        let datas = { email, password }
        axios.post(this.URL + '/v1/user_login', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    get_user_by_token(token, callback) {
        let datas = { token }
        axios.post(this.URL + '/v1/get_user_by_token', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    get_contrat_from_num_contrat(numContrat, callback) {
        let datas = { numContrat }
        axios.post(this.URL + '/v1/get_contrat_from_num_contrat', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    get_societe_by_id(id, callback) {
        let datas = { id }
        axios.post(this.URL + '/v1/get_societe_by_id', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    get_societe_by_id_soft(id, callback) {
        let datas = { id }
        axios.post(this.URL + '/v1/get_societe_by_id_soft', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    get_agency(callback) {
        let datas = {}
        axios.post(this.URL + '/v1/get_agency', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    get_agency_soft(callback) {
        let datas = {}
        axios.post(this.URL + '/v1/get_agency_soft', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    get_clients(callback) {
        let datas = {}
        axios.post(this.URL + '/v1/get_clients', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

}

export default new dataApi()