<template>
  <div class="modal planing-livraison" align="center">
    <div
      class="modal-content"
      align="left"
      style="width: 700px; padding: 20px; margin-top: 15vh; font-size: 20px"
    >
      <div align="center"><h3>Technicien à afficher</h3></div>
      <div style="margin-top: 10px; width: 100%" align="center">
        <v-text-field
          placeholder="Filtrer"
          hide-details
          density="compact"
          class="mb-2"
          variant="outlined"
          v-model="filter"
          persistent-clear
          :clearable="true"
          style="background-color: white"
        ></v-text-field>
        <div
          align="left"
          style="
            border: solid 1px grey;
            border-radius: 5px;
            height: 250px;
            overflow-y: auto;
            background-color: white;
          "
        >
          <div
            v-for="(tech, idx) in getTekListFiltered"
            :key="idx"
            @click="setTechState(tech.id)"
            class="hover_in_mouseIn"
          >
            <input
              :checked="selectedtechToSow[tech.id] == true"
              type="checkbox"
              :id="tech.id"
              style="width: 30px"
            />
            {{ tech.title }}
          </div>
        </div>
        <div align="left" @click="selecteAll()" class="mt-2">
          <input
            :checked="allTechSelected"
            type="checkbox"
            style="width: 30px"
          />
          Tout séléctionner / déselectionner
        </div>
      </div>
      <div align="right" style="margin-top: 20px">
        <v-btn
          @click="saveTechToShow"
          class="modal-btn"
          style="background-color: green; color: white"
        >
          Enregistrer
        </v-btn>
        <v-btn
          @click="closeModal"
          class="modal-btn"
          style="background-color: red; color: white"
        >
          Fermer
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import planningLivraison from "../core/index";
export default {
  data() {
    return {
      selectedtechToSow: {},
      filter: "",
    };
  },
  props: {
    data: {},
  },
  mounted() {
    for (let i = 0; i < this.data.technicienListToShow.length; i++) {
      this.selectedtechToSow[this.data.technicienListToShow[i]] = true;
    }
  },
  computed: {
    allTechSelected() {
      if (
        this.data.technicienList.length ==
          Object.entries(this.selectedtechToSow).length &&
        Object.entries(this.selectedtechToSow).findIndex(
          (val) => val[1] == false
        ) == -1
      )
        return true;
      return false;
    },
    getTekListFiltered() {
      if (this.filter.length == 0) return this.data.technicienList;
      else {
        return this.data.technicienList.filter((val) =>
          val.id.includes(this.filter)
        );
      }
    },
  },
  methods: {
    saveTechToShow() {
      let techs = [];
      let that = this;
      for (const [key, value] of Object.entries(this.selectedtechToSow)) {
        if (value == true) {
          techs.push(key);
        }
      }
      let token = localStorage.getItem("userToken");
      planningLivraison.set_technicien_liste_to_show(
        techs.join(","),
        token,
        function () {
          that.$emit("updatetechnicientsListToShow", techs);
        }
      );
    },
    selecteAll() {
      let techList = {};
      if (this.allTechSelected == true) {
        for (let i = 0; i < this.data.technicienList.length; i++) {
          techList[this.data.technicienList[i].id] = false;
        }
      } else {
        for (let i = 0; i < this.data.technicienList.length; i++) {
          techList[this.data.technicienList[i].id] = true;
        }
      }
      this.selectedtechToSow = techList;
    },
    setTechState(tekId) {
      if (this.selectedtechToSow[tekId] == undefined)
        this.selectedtechToSow[tekId] = true;
      else {
        this.selectedtechToSow[tekId] = !this.selectedtechToSow[tekId];
      }
    },
    closeModal() {
      this.$emit("close");
    },
  },
};
</script>

<style>
.planning-livraison .hover_in_mouseIn:hover {
  background-color: lightblue;
}
</style>